import React from 'react';

import classes from './DotAnimation.module.scss';

const DotAnimation = props => (
      props.white ?
      <div className={classes.main}>
            <div className={classes.dot_pulse}></div>
      </div> :
      <div className={`${classes.dot_flashing} ${classes.dark}`}></div>
);

export default DotAnimation;



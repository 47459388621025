// Marker.js
import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as MarkerIcon} from "../../../../../assets/img/icons/marker.svg";

const Location = ({text, onClick, view}) => (
  <MarkerIcon
    style={{opacity: Boolean(view) ? 1 : 0}}
    className='marker-icon'
    alt={text}
    onClick={onClick}
  />
);

Location.defaultProps = {
  onClick: null,
};

Location.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  view: PropTypes.bool.isRequired,
};

export default Location;

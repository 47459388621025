import { useLayoutEffect, useEffect, useState } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom'; 

import withWidthPicker from '../../hoc/withWidthPicker/withWidthPicker';
import Home from '../../components/Home';
import Layout from '../../components/_common/Layout';

const Main = ({ width }) => {
	const history = useHistory();
	const location = useLocation();
	const [macOS, setMacOS] = useState(navigator.platform.toLowerCase().indexOf('mac') >= 0);

	useLayoutEffect(() => window.executeHome(), []);

	useEffect(() => {
		setMacOS(navigator.platform.toLowerCase().indexOf('mac') >= 0);
		if (width <= 768)  history.push(`/?map`);
	}, [location]);

	return (macOS
		? <Redirect to='/?map' />
		: <Layout>
			<Home />
		</Layout>
	);
};

export default withWidthPicker(Main);
import React from 'react';

const Faq = () => (
  <section className="faq">
    <div className="container">
      <h2 className="title-xl">Frequently Asked Questions</h2>
      <div className="faq-wrap">
        <ul className="faq-list">
          <li>
            <div className="faq-list_title">
              <h3>1. What is ParkEagles?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>
                2. How does ParkEagles work?
              </h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                Our platform helps you access private parking spaces in a short period. ParkEagles gives an everyday alternative to public garages
                conveniently and for less money. The combination of free street parking + cheap private parking allows you to save money and be more flexible in your plans.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>3. How much is cost?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                ParkEagles is not the owner of the parking lot, so the price depends on the owners' request. You can see the cost per hour, per time slot, and special price offers.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>4. Is ParkEagles charge any fee?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                Yes, a service fee is added to each transaction. You can see it before paying.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>5. What cities is ParkEagles in?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                Right now we are filling the base of private parking in New York.<br />
                Boston, Washington D.C, Philadelphia, Chicago, Los Angeles, San Francisco, Baltimore, Atlanta, Portland, Seattle will be next
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>6. How do I find a spot on private parking?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                1. Use Navigation or Parking<br />
                &nbsp; &nbsp;a) Navigation. Choose the location where you are going. Receive offers with private parking near
                arrival address 10 minutes before.<br />
                &nbsp; &nbsp;b) Parking. See the list/map of garages around your location.<br />
                2. Book / Pay<br />
                3. Using our navigation, get to the parking lot<br />
                4. Park<br />
                5. Find your way back to the car
              </p>
            </div>
          </li>
        </ul>
        <ul className="faq-list">
          <li>
            <div className="faq-list_title">
              <h3>7. How far in advance can I reserve parking?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                You can reserve anywhere from minutes in advance; you just need to purchase a reservation before you enter the parking space.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>8. Is ParkEagles liable for vehicle damage or stolen items?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                Because ParkEagles does not own or manage the parking spaces we partner with, ParkEagles is not responsible for theft, damage to your vehicle, or personal property.<br />
                Just like any other time you park, please keep your vehicle locked and do not leave valuables in your car when you have a ParkEagles reservation.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>9. What is a special price for 6-12 hours?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                This is a fixed price that you receive if your parked time at the time of payment was 6 to 12 hours.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>10. What is a special price for 12-24 hours?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                This is a fixed price that you receive if your parked time at the time of payment was 12 to 24 hours.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>11. Is your site secure?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                Yes! We have a secure checkout process. All of our billing goes through Stripe, a multi-million dollar company that operates in 20 countries, and we don’t store full credit card information anywhere on our site.
                Feel free to visit https://stripe.com/ for more information.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>12. What is green GO (successful parking)? </h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                This indicates the number of successful parking sessions.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>13. What is red STOP (troubled parking)? </h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                This indicates the number of unsuccessful parking sessions.
              </p>
            </div>
          </li>
          <li>
            <div className="faq-list_title">
              <h3>14. What types of payments does the ParkEagles accept?</h3>
            </div>
            <div className="faq-list_text">
              <p className="descr-sm">
                ParkEagles accepts all credit/deposit cards as well as ApplePay.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Faq;


import React, { useState, useLayoutEffect } from 'react';

const withWidthPicker = ( WrappedComponent ) => {
    return props => {
        
        const [width, setWidth] = useState(window.innerWidth);

        useLayoutEffect(() => {
            function handleResize() {
                if (window.innerWidth > 1024) {
                    document.querySelector('.header-menu').style.display = 'block';
                } else {
                    document.querySelector('.header-menu').style.display = 'none';
                }
                setWidth(window.innerWidth);
            }
        
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);
    
        return (
            <React.Fragment>
                <WrappedComponent {...props} width={width}/>
            </React.Fragment>
        );
    }
}


export default withWidthPicker;
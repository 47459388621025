import React from 'react';
import Star from '../../assets/img/icons/stars-1.svg';
import CommentImg1 from '../../assets/img/images/space/comments/1.png';
import CommentImg2 from '../../assets/img/images/space/comments/2.png';
import CommentImg3 from '../../assets/img/images/space/comments/3.png';
import CommentImg4 from '../../assets/img/images/space/comments/4.png';
import CommentImg5 from '../../assets/img/images/space/comments/5.png';
import CommentImg6 from '../../assets/img/images/space/comments/6.png';

const Comments = () => (
  <section className="comments">
    <div className="container">
      <h2 className="title-xl comments-mob-font">Host trust us</h2>
      <ul className="comments-boxs">
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg1} alt="Jared J."/>
            <h3 className="title-sm">Sam Y.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">A great way to optimize my car ownership costs. My parking lot pays for my parking
              outside and gas.</p>
          </div>
        </li>
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg2} alt="Adelia N."/>
            <h3 className="title-sm">David H.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">Now I earn more from sharing space than when I gave it away. Really works.</p>
          </div>
        </li>
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg3} alt="Jared J."/>
            <h3 className="title-sm">Sarah C.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">Excellent. I have a good location next to a business street that gives me
              $200/week.</p>
          </div>
        </li>
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg4} alt="Jared J."/>
            <h3 className="title-sm">Barbara J.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">The main benefit that I noted is that there are no fake bookings, which makes the
              most efficient use of time.</p>
          </div>
        </li>
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg5} alt="Jared J."/>
            <h3 className="title-sm">Ryan V.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">
              Low commission and wide pricing options were my most important criteria for choosing ParkEagles.
            </p>
          </div>
        </li>
        <li>
          <div className="comments-boxs_top">
            <img src={CommentImg6} alt="Jared J."/>
            <h3 className="title-sm">Amir T.</h3>
          </div>
          <div className="comments-boxs_bottom">
            <img src={Star} alt="star"/>
            <p className="descr-sm">
              The mission of the service bribed me. Share what you don't use and get paid for it.
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
);

export default Comments;


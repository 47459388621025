import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import {useCookies} from 'react-cookie';

import ScrollToTop from './components/_common/ScrollToTop/ScrollToTop';

import HomePage from './pages/Main';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';
import CookiePage from "./pages/Cookie";
import DisclaimerPage from "./pages/Disclaimer";
import ContactPage from "./pages/Contact";
import SpaceListPage from "./pages/SpaceList";
// import BusinessPage from "./pages/Business";
import DownloadPage from "./pages/Download";
import MapPage from "./pages/Map";
import MapDetailsPage from "./pages/Map/Details";
import ParkingRouter from "./pages/Parking/Router";
import LoginRouter from "./pages/Login";
import ParkingPage from "./pages/Parking";
import PrivateParkingPage from "./pages/Parking/Private";
import GaragesPage from "./pages/Parking/Garages";
import StreetParkingPage from "./pages/Parking/Street";

function App() {
  const [cookies] = useCookies(['userId']);
  return (
    <Router basename="/">
      <ScrollToTop/>
      <Switch>
        <Route exact path="/" component={MapPage}/>
        <Route path="/privacy" component={PrivacyPage}/>
        <Route path="/terms" component={TermsPage}/>
        <Route path="/cookie" component={CookiePage}/>
        <Route path="/disclaimer" component={DisclaimerPage}/>
        <Route path="/contact" component={ContactPage}/>
        <Route path="/rent-out-my-driveway" component={SpaceListPage}/>
        <Route path="/list-your-space" component={SpaceListPage}/>
        <Route path="/customize-list" component={SpaceListPage}/>
        <Route path="/private-parking-process" component={SpaceListPage}/>
        <Route path="/hosts-service-fee" component={SpaceListPage}/>
        {/* <Route exact path="/business" component={BusinessPage}/> */}
        <Route path="/download" component={DownloadPage}/>
        {/* <Route exact path="/map" component={MapPage}/> */}
        <Route path="/map/:id" component={MapDetailsPage}/>
        <Route path="/login" component={LoginRouter}/>
        <Route exact path="/how-it-works" component={ParkingPage}/>
        <Route path="/how-it-works/private" component={PrivateParkingPage}/>
        <Route path="/how-it-works/garage" component={GaragesPage}/>
        <Route path="/how-it-works/street" component={StreetParkingPage}/>
        <Route path="/:city" component={MapPage}/>
        <Route path="/:city/:area" component={MapPage}/>
        <Route path="/">
          {cookies.userId && cookies.userId !== 'undefined' ?
            <Redirect to="/"/> :
            <Redirect to="/login"/>}
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../components/_common/Layout';
import { Link } from 'react-router-dom';
import Img1 from '../../assets/img/images/login/login-1.svg';
import Img2 from '../../assets/img/images/login/login-2.svg';

const LoginPage = () => (
  <Layout>
    <MetaTags>
      <meta name="robots" content="noindex, nofollow" />
    </MetaTags>
    <section className="section-two login">
      <div className="container">
        <h1 className="section-title">Login</h1>
        <div className="login-wrap">
          <a href="/dashboard" className="login-box">
            <img src={Img1} alt="img" />
            <span className="button">Residential</span>
          </a>
          <a href={`/contact`} className="login-box">
            <img src={Img2} alt="img" />
            <span className="button">Business</span>
          </a>
        </div>
      </div>
    </section>
  </Layout>
);

export default LoginPage;


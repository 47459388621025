import React, { useState } from 'react';

import { getDuration } from "../../utils/map";
import classnames from "classnames";
import MapSingle from "./MapSingle";
import DotAnimation from "../_common/UI/DotAnimation/DotAnimation";
import CitiesList from "./CitiesList";

const sortCompare = (a, b, field) => {
  const aField = parseFloat(a[field]);
  const bField = parseFloat(b[field]);
  if (aField < bField) return -1;
  if (aField > bField) return 1;
  return 0;
}

const MapList = ({ places, tab, loading }) => {
  const [sort, setSort] = useState('price');

  if (!places.length && tab === 'list') {
    return (
      <div className="map-error active">
        {loading ? <DotAnimation /> : <>
          <p>Parking are not available in your area</p>
          <CitiesList tab='list' />
        </>}
      </div>
    );
  }

  return (
    <div className={classnames("map-info_wrap", tab === 'list' && "active")}>
      <div className="select-wrap map-select">
        <div className="select">
          <select
            name="map-select"
            id="map-select"
            value={sort}
            onChange={({ target: { value } }) => setSort(value)}
          >
            <option value="price">Sort by Price</option>
            <option value="duration">Sort by Distance</option>
          </select>
        </div>
      </div>
      {
        places
          .map(place => ({
            ...place,
            duration: getDuration(place)
          }))
          .sort((a, b) => sortCompare(a, b, sort))
          .map(place => <MapSingle key={place.id} place={place} duration={place.duration} />)
      }
      <CitiesList tab='list' />
    </div>
  );
}

export default MapList;


import React from 'react';

import AcceptImg1 from '../../assets/img/images/space/accept/1/1.svg';
import AcceptImg2 from '../../assets/img/images/space/accept/1/2.svg';

import AcceptImg3 from '../../assets/img/images/space/accept/2/1.svg';
import AcceptImg4 from '../../assets/img/images/space/accept/2/2.svg';

import AcceptImg5 from '../../assets/img/images/space/accept/3/1.svg';
import AcceptImg6 from '../../assets/img/images/space/accept/3/2.svg';

import AcceptImg7 from '../../assets/img/images/space/accept/4/1.png';
import AcceptImg8 from '../../assets/img/images/space/accept/4/2.png';

const Accept = () => (
  <>
    <section className="accept" id="list-your-space">
      <h2 className="title-xl text-center youtube-link">
        <a href='https://www.youtube.com/watch?v=Z4hWFXTauGE' target='_blank'>How It Works</a>
      </h2>
      <div className="container">
        <div className="accept-wrap">
          <div className="accept-text">
            <h2 className="title-xl">1. User-Friendly Interface</h2>
            <p className="descr-lg">Choose a convenient way to list your parking space</p>
          </div>
          <div className="accept-image">
            <ul className="accept-tabs">
              <li>App</li>
              <li>Web</li>
            </ul>
            <div className="accept-boxs">
              <div className="accept-box">
                <span>App</span>
                <img src={AcceptImg1} alt="App" />
              </div>
              <div className="accept-box">
                <span>Web</span>
                <img src={AcceptImg2} alt="App" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="accept two" id="сustomize-list">
      <div className="container">
        <div className="accept-wrap">
          <div className="accept-text">
            <h2 className="title-xl">2. Pricing</h2>
            <p className="descr-lg">Use value and time propositions to maximize<br className='desktop-view' />
              demand</p>
          </div>
          <div className="accept-image">
            <ul className="accept-tabs">
              <li>24 Hours</li>
              <li>Custom</li>
            </ul>
            <div className="accept-boxs">
              <div className="accept-box">
                <span>24 Hours</span>
                <img src={AcceptImg3} alt="Special Price" />
              </div>
              <div className="accept-box">
                <span>Custom</span>
                <img src={AcceptImg4} alt="Available Until" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="accept" id="private-parking-process">
      <div className="container">
        <div className="accept-wrap">
          <div className="accept-text">
            <h2 className="title-xl">3. Under control</h2>
            <p className="descr-lg">Keep up to date with the process</p>
          </div>
          <div className="accept-image">
            <ul className="accept-tabs">
              <li>Parking Session</li>
              <li>Push/SMS</li>
            </ul>
            <div className="accept-boxs">
              <div className="accept-box">
                <span>Parking Session</span>
                <img src={AcceptImg5} alt="Parked" />
              </div>
              <div className="accept-box">
                <span>Push/SMS</span>
                <img src={AcceptImg6} alt="Earn" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="accept two" id="hosts-service-fee">
      <div className="container">
        <div className="accept-wrap">
          <div className="accept-text">
            <h2 className="title-xl">4. Profit</h2>
            <p className="descr-lg">Get the earned money to your bank account weekly</p>
          </div>
          <div className="accept-image">
            <ul className="accept-tabs">
              <li>Earnings</li>
              <li>PayOut</li>
            </ul>
            <div className="accept-boxs">
              <div className="accept-box">
                <span>Earnings</span>
                <img src={AcceptImg7} alt="Earnings" />
              </div>
              <div className="accept-box">
                <span>PayOut</span>
                <img src={AcceptImg8} alt="Available Until" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Accept;


import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../../components/_common/Layout';
import SearchBox from '../../../components/_common/SearchBox';

import PhoneIcon from '../../../assets/img/icons/phone.svg';
import CarIcon from '../../../assets/img/icons/car-2.svg';
import ParkIcon from '../../../assets/img/icons/sign.svg';

import AppIcon from '../../../assets/img/icons/app.svg';

import MarketplaceIcon1 from '../../../assets/img/images/garages/marketplace/1.svg';
import MarketplaceIcon2 from '../../../assets/img/images/garages/marketplace/2.svg';
import MarketplaceIcon3 from '../../../assets/img/images/garages/marketplace/3.svg';

import AcceptIcon1 from '../../../assets/img/images/garages/accept/1/1.png';
import AcceptIcon2 from '../../../assets/img/images/garages/accept/1/2.png';
import AcceptIcon3 from '../../../assets/img/images/garages/accept/1/3.png';
import AcceptIcon4 from '../../../assets/img/images/garages/accept/2/1.png';
import AcceptIcon5 from '../../../assets/img/images/garages/accept/2/2.png';
import AcceptIcon6 from '../../../assets/img/images/garages/accept/3/1.png';
import AcceptIcon7 from '../../../assets/img/images/garages/accept/3/2.png';
import First from "../../../components/_common/Mission/First";
import DownloadForm from "../../../components/Download/DownloadForm";
import AIcon from "../../../assets/img/icons/a.svg";
import Faq from '../../../components/_common/Faq';

const faqList = [
  [
    'What is ParkEagles?',
    'ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out.',
  ],
  [
    'How does ParkEagles work?',
    'We have partnered with various parking operators across the country to provide you with multiple parking options at the lowest prices. Our site allows parking owners to download hot offers with a limited duration instantly. We will help you find Early bird deals, Special hours, Evening Special and contactless pay',
  ],
  [
    'How do I find a spot on parking garage?',
    '1. Use Navigation or Parking<br/>a) Navigation. Choose the location where you are going. Get offers with garages neararrival address 10 minutes before.<br/>b) Parking. See the list/map of garages around your location.<br/>2. Book / Pay<br/>3. Get a parking pass (code)<br/>4. Using our navigation, get to the parking lot',
  ],
  [
    'Is ParkEagles charge any fee?',
    'Yes, a service fee is added to each transaction. You can see it before paid',
  ],
  [
    'What cities is ParkEagles in?',
    'Right now we are filling the base of private parking in New York. Boston, Washington D.C, Philadelphia, Chicago, Los Angeles, San Francisco, Baltimore, Atlanta, Portland, Seattle will be next',
  ],
  [
    'Does ParkEagles partner with all garages?',
    'No, but we work every day to be present in all locations.',
  ],
  [
    'Can I sell my parking pass?',
    'No, selling our parking passes through a third-party site is a violation of our terms and conditions.',
  ],
  [
    'Why do ParkEagles ask me for my license plate number?',
    'Parking reservations are tracked by license plate, you must enter this information at checkout and prior to arrival.',
  ],
  [
    'Why can’t I extend my reservation?',
    'There are a few reasons why you may have trouble extending a reservation.<br/><br/>If the spot is sold out or the garage cannot accept extensions, unfortunately, an extension isn’t possible. Please do not purchase a second reservation if unable to extend as locations cannot process multiple reservations. You can always give us a call to see if we can help!',
  ],
  [
    'What if something goes wrong with my reservation?',
    'If you have any questions, please write to us at office@parkeagles.com',
  ],
  [
    'What is an oversize fee?',
    'It’s common for New York City garages to charge an extra fee for oversized vehicles. All fees including the oversize fee will appear on the checkout page before purchase.<br/><br/>Though there is some variation between garages, based on guidance from our operator partners, ParkEagles typically defines oversized vehicles as any vehicle 65″ to 75″ tall and over 181″ long, or any vehicle taller than 75″ regardless of length.<br/><br/>Standard-Size Vehicles include but are not limited to:<br/><br/>2-door cars (Ford Focus)<br/><br/>4 door sedans (BMW 7-series)<br/><br/>Oversized Vehicles include but are not limited to:<br/><br/>SUVs (Toyota Highlander)<br/><br/>Minivans (Dodge Grand Caravan)',
  ],
  [
    'What is a parking pass?',
    'We send you a parking pass to the email address you provide. Parking pass is your access pass to the facility. You will see time, price, address, phone number, and license plate number (must match).<br/>You can also find the parking pass in the mobile app.',
  ],
  [
    'Can I get a parking invoice?',
    'Yes, with every transaction, we send an invoice by email (enter it during registration/booking)',
  ],
  [
    'Do I have to enter and exit at the exact times on the parking pass?',
    'You can park anytime after the start time on your ParkEagles booking, and you can leave anytime before the end time on your booking. any time spent Parking outside of your reservation is subject to a ticket, towing, or additional charges (at the rate of entry).',
  ]
];

const missionText = 'We provide drivers with the best parking options in parking garages. Our partners place a limited number of parking spaces at discounted prices every day. Only with ParkEagles users get a parking pass at the lowest price.';

const PrivateParkingPage = () => {
  React.useLayoutEffect(() => window.executeHome(), []);
  return (
    <Layout>
      <MetaTags>
        <title>ParkEagles: Find and Book Parking Garages Near</title>
        <meta id="meta-description" name="description" content="Find the [best parking options] in parking garages. Our partners place a limited number of parking spaces at [discounted prices every day]. Reserve a parking pass at the lowest price" />
        <link rel="canonical" href="https://parkeagles.com/how-it-works/garage" />
      </MetaTags>
      <section className="home three">
        <div className="section-bgr" />
        <div className="section-down" />
        <div className="container">
          <div className="home-wrap">
            <div className="home-text">
              <h1 className="title-lg">Garages</h1>
              <p className="descr-xl">Plan less<br />Park immediately<br /> Discount offers at any time</p>
              <div className="home-text_wrap">
                <SearchBox placeholder={''} />
              </div>
            </div>
            <div className="home-img">
              <img src={PhoneIcon} alt="phone" />
              <img src={CarIcon} alt="car" />
              <img src={ParkIcon} alt="park" />
            </div>
          </div>
        </div>
      </section>
      <div className="marketplace-mob">
        <div className="container search-box">
          <SearchBox placeholder={''} />
        </div>
      </div>
      <section className="marketplace">
        <div className="container">
          <div className="marketplace-wrap">
            <h2 className="title-xl">Special Deals All Day</h2>
            <ul className="marketplace-list">
              <li>
                <img src={MarketplaceIcon1} alt="marketplace" />
                <h3 className="title-md">Discount Prices</h3>
              </li>
              <li>
                <img src={MarketplaceIcon2} alt="marketplace" />
                <h3 className="title-md">Contactless Payment</h3>
              </li>
              <li>
                <img src={MarketplaceIcon3} alt="marketplace" />
                <h3 className="title-md">Stress-free Parking</h3>
              </li>
            </ul>
            <h4 className="title-xl">#1 Parking Marketplace</h4>
          </div>
        </div>
      </section>
      <section className="accept">
      <h2 className="title-xl text-center youtube-link">
        <a href='https://www.youtube.com/watch?v=Z4hWFXTauGE' target='_blank'>How It Works</a>
      </h2>
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">1. Look & Book</h2>
              <p className="descr-lg">Find the best place at the best price</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Near</li>
                <li>Around</li>
                <li>There</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Near</span>
                  <img src={AcceptIcon1} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>Around</span>
                  <img src={AcceptIcon2} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>There</span>
                  <img src={AcceptIcon3} alt="Near" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept two">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">2. Book & Pay</h2>
              <p className="descr-lg">Choose a convenient type of payment for you (Visa, Master Card, Discovery, Amex, Maestro, ApplePay, GooglePay)</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Preview</li>
                <li>ApplePay</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Preview</span>
                  <img src={AcceptIcon4} alt="Navigation" />
                </div>
                <div className="accept-box">
                  <span>ApplePay</span>
                  <img src={AcceptIcon5} alt="Like" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">3. Go & Park</h2>
              <p className="descr-lg">Built-in navigation will find the best path for you</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Navigation</li>
                <li>Parking Pass</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Navigation</span>
                  <img src={AcceptIcon6} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>Parking Pass</span>
                  <img src={AcceptIcon7} alt="Near" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq list={faqList} />
      <First priv street text={missionText} />
    </Layout>
  );
}

export default PrivateParkingPage;



import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/analytics';

// // local app details:
// const firebaseConfig = {
//     apiKey: "AIzaSyC723F8pHLUVe751Sx0Xk5FaOxLIsfdFu4",
//     authDomain: "park-eagles-dev.firebaseapp.com",
//     databaseURL: "https://park-eagles-dev-default-rtdb.firebaseio.com",
//     projectId: "park-eagles-dev",
//     storageBucket: "park-eagles-dev.appspot.com",
//     messagingSenderId: "238082816921",
//     appId: "1:238082816921:web:59512ce6a065b3417a3108",
//     measurementId: "G-T02YLD5QSZ"
// };


// destination app details:
const firebaseConfig = {
    apiKey: "AIzaSyBHw0WslYeBryr8V-BcGQbFaYLoMsk37E0",
    authDomain: "parkeaglesapp-3a62a.firebaseapp.com",
    databaseURL: "https://parkeaglesapp-3a62a.firebaseio.com",
    projectId: "parkeaglesapp-3a62a",
    storageBucket: "parkeaglesapp-3a62a.appspot.com",
    messagingSenderId: "523533451157",
    appId: "1:523533451157:web:349a4713ff8741ef19009c",
    measurementId: "G-KJEC7382GE"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// let messaging = null
// if (firebase.messaging.isSupported()) {
//    messaging = firebase.messaging()
//    messaging.usePublicVapidKey("{yourkey")
// }
// export { messaging }

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();


// const key = 'BBoHixCXz1buxDmKxDlz942z5EAiAhIdum2LtcGpz6rqwUg0-5EIEXujF5LVbXhQV0CZPjTwYMPqBSXc5xqR4MY'

// export const requestFirebaseNotificationPermission = () => {
//   if (messaging) {
//     return new Promise((resolve, reject) => {
//       messaging.requestPermission()
//         .then(() => messaging.getToken({vapidKey: key}))
//         .then((firebaseToken) => {
//           resolve(firebaseToken);
//         })
//         .catch((err) => {
//           reject(err);
//         });
//     })
//   }
// }


// export const onMessageListener = () => {
//   if (messaging) {
//     return new Promise((resolve) => {
//       messaging.onMessage((payload) => {
//         resolve(payload);
//       });
//     });
//   }
// }
  
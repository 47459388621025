import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import * as actions from "../../../redux/actions";

import Header from '../Navigation/Header';
import Footer from '../Footer';

const Layout = props => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const setMenu = menu => dispatch(actions.setMenu(menu));

  const openLogoutModal = () => {
    setMenu(false);
    setIsOpen(true);
  }
  return (
    <>
      <Header openModal={openLogoutModal}/>
      <main className="main">
        {props.children}
      </main>
      <Footer />
    </>
  );
}


export default Layout;

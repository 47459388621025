import React from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import Layout from '../../components/_common/Layout';
import ContactForm from '../../components/Contact/ContactForm';

const ContactPage = () => {
  return (
    <Layout>
      <MetaTags>
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <section className="section-two contact">
        <div className="container">
          <h1 className="section-title">Contact</h1>
          <ContactForm />
          <div className="contact-thank">
            <p className="descr-sm">Your message was successfully sent. Our specialists will contact you shortly.</p>
            <a className="button" href={`/contact`}>
              Main page
            </a>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;


import React from 'react';
import { useHistory } from 'react-router-dom';
import Autocomplete from 'react-google-autocomplete';

const SearchBox = () => {
  const history = useHistory();

  const setLocation = (location) => {
    localStorage.setItem('location', JSON.stringify(location));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    history.push('/?map');
  };

  const handleSearchNear = (e) => {
    e.preventDefault();

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position, error) => {
        if (!error) {
          localStorage.setItem('currentLocation', JSON.stringify([
            position.coords.latitude, position.coords.longitude
          ]));
          setLocation({
            center: [position.coords.latitude, position.coords.longitude],
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            zoom: 16,
            isDefault: false,
            address: ''
          });
        }
      });

      history.push('/?map');
    }
  };

  return (
    <>
      <form className='download-form google-form'>
        <label className='download-input'>
          <Autocomplete className='download-input_point light'
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            onPlaceSelected={({ formatted_address, geometry }) => {
              const lat = geometry.location.lat();
              const lng = geometry.location.lng();
              const location = {
                address: formatted_address,
                center: [lat, lng],
                lat,
                lng,
                isDefault: true,
                zoom: 15
              };

              setLocation(location);
            }}
            options={{
              types: ['address']
            }
            }
          />
        </label>
        <button onClick={handleSearch} className='button two'>Search</button>
      </form>
      <a className='search-box-near' onClick={handleSearchNear}>Find Parking Near</a>
    </>
  )
};

export default SearchBox;
import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../components/_common/Layout';

const DisclaimerPage = () => (
  <Layout>
    <MetaTags>
      <meta name="robots" content="noindex, nofollow" />
    </MetaTags>
    <section className="section-one single">
      <div className="container">
        <h1 className="section-title">Disclaimer Policy</h1>
        <div className="single-wrap">
          <h3>ParkEagles Corp.</h3>
          <p><b>Last updated: April 29, 2020</b></p>
          <p>Please read the Disclaimer Policy carefully before using Our Service.</p>
          <p><b>Disclaimers for ParkEagles:</b></p>
          <ol>
            <li>All the information on this mobile app and on our website is published in good faith and for information
              regarding street parking places purposes only. ParkEagles does not make any warranties about the
              completeness, reliability and accuracy of the information provided by users. Any action you take upon the
              information you find on this mobile app (ParkEagles), is strictly at your own risk. ParkEagles will not be
              liable for any losses and/or damages in connection with the use of our app.
            </li>
            <li>All users need to act on the goodwill and they have to provide only real information and to be in
              compliance with United States federal law regarding parking regulations and standard, also state laws
              where the parking spot is located (where is applicable).
            </li>
            <li>Such as the 32 CFR § 1903.5 - Enforcement of parking regulations.</li>
            <li>Our service is not a platform where users sell an affordable place in a street parking lot. This is a
              platform and a community of drivers who will share information and a parking space among themselves
              according to the formula Share a place got a place next time.
            </li>
            <li>ParkEagles do not charge a fee for a place on a street parking, and we are not a platform on which any
              payments for ownership will be made for the use of public parking because ParkEagles is not selling and
              does not own parking spots, it is only an app that allows users to share available street parking spots
              among themselves.
            </li>
          </ol>
          <p>Please be also aware that when you leave our mobile app or our website, other sites may have different
            privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of
            these sites as well as their "Terms of Service" before engaging in any business or uploading any
            information.</p>
          <p><b>Consent</b></p>
          <p>By using our mobile app, you hereby consent to our disclaimer and agree to its terms.</p>
          <p><b>Update</b></p>
          <p>Should we update, amend or make any changes to this document, those changes will be prominently posted
            here.</p>
          <p><b>Contact Us</b></p>
          <p>If you have any questions about the Disclaimer Policy, You can contact us:</p>
          <ul>
            <li>By email: <a href="mailto:support@parkeagles.com">support@parkeagles.com</a></li>
            <li>y visiting this page on our website: <a href="https://www.parkeagles.com"
              target="blank">parkeagles.com</a></li>
          </ul>
        </div>
      </div>
    </section>
  </Layout>
);

export default DisclaimerPage;


import React from 'react';
import MetaTags from 'react-meta-tags';
import GoogleIcon from '../../assets/img/icons/google.svg';
import DownloadIcon from '../../assets/img/icons/download.svg';
import AppIcon from '../../assets/img/icons/app-black.svg';

import WaitListForm from './WaitListForm';
import DownloadForm from './DownloadForm';

const DownloadPage = () => (
  <>
    <MetaTags>
      <title>[#1 Parking App NYC] ParkEagles *Free App</title>
      <meta id="meta-description" name="description" content="Smart Navigation + Parking Map + Find a Car + ASP Notifications + Online Payment = All-in-One App. Get the parking assistant for every day. [Download Now]" />
      <link rel="canonical" href="https://parkeagles.com/download" />
    </MetaTags>
    <section className="section-two download desktop">
      <div className="container">
        <div className="download-wrap">
          <div className="download-box">
            <a className="download-box_google" target="_blank" rel="noopener" aria-label="google">
              <img src={GoogleIcon} alt="google" />
            </a>
            <h3 className="title-md">Wait list</h3>
            <WaitListForm />
          </div>
          <div className="download-img">
            <img src={DownloadIcon} alt="Download" />
          </div>
          <div className="download-box">
            <a href="https://apps.apple.com/us/app/parkeagles-1-nyc-parking-app/id1511592331"
              className="download-box_app" target="_blank" rel="noopener" aria-label="app">
              <img src={AppIcon} alt="app" />
            </a>
            <h3 className="title-md">Download now</h3>
            <DownloadForm />
          </div>
        </div>
        <h1 className="section-title"><br />Get the Parking Assistant for Every Day.<br /><br />
          Smart Navigation + Parking Map + Find a Car + <br />
          ASP Notifications + Online Payment =<br />
          All-in-One App.
        </h1>
      </div>
    </section>
    <section className="section-two download mobile">
      <div className="container">
        <div className="download-wrap">
          <h1 className="section-title mob"><br />Smart Navigation + Parking Map + Find a Car +
            ASP Notifications + Online Payment =
            All-in-One App.
          </h1>
          <div className="download-box">
            <a href="https://apps.apple.com/us/app/parkeagles-1-nyc-parking-app/id1511592331"
              className="download-box_app" target="_blank" rel="noopener" aria-label="app">
              <img src={AppIcon} alt="app" />
            </a>
            <h3 className="title-md">Download now</h3>
            <DownloadForm />
          </div>
          <div className="download-or">OR</div>
          <div className="download-box">
            <a className="download-box_google" target="_blank" rel="noopener" aria-label="google">
              <img src={GoogleIcon} alt="google" />
            </a>
            <h3 className="title-md">Wait list</h3>
            <WaitListForm />
          </div>
          <div className="download-img">
            <img src={DownloadIcon} alt="Download" />
          </div>
          <h1 className="section-title mob-bottom"><br />Get the Parking Assistant for Every Day.
          </h1>
        </div>
      </div>
    </section>
  </>
);

export default DownloadPage;


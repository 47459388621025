import React from 'react';
import MetaTags from 'react-meta-tags';

import Layout from '../../../components/_common/Layout';
import First from '../../../components/_common/Mission/First';
import SearchBox from '../../../components/_common/SearchBox';

import PhoneIcon from '../../../assets/img/icons/phone.svg';
import CarIcon from '../../../assets/img/icons/car-2.svg';
import ParkIcon from '../../../assets/img/icons/park.svg';
import AppIcon from '../../../assets/img/icons/app.svg';

import MarketplaceIcon1 from '../../../assets/img/images/private-parking/marketplace/1.svg';
import MarketplaceIcon2 from '../../../assets/img/images/private-parking/marketplace/2.svg';
import MarketplaceIcon3 from '../../../assets/img/images/private-parking/marketplace/3.svg';

import AcceptIcon1 from '../../../assets/img/images/private-parking/accept/1/1.svg';
import AcceptIcon2 from '../../../assets/img/images/private-parking/accept/1/2.svg';
import AcceptIcon4 from '../../../assets/img/images/private-parking/accept/2/1.svg';
import AcceptIcon5 from '../../../assets/img/images/private-parking/accept/2/2.svg';
import AcceptIcon6 from '../../../assets/img/images/private-parking/accept/3/1.svg';
import AcceptIcon7 from '../../../assets/img/images/private-parking/accept/3/2.svg';
import AIcon from "../../../assets/img/icons/a.svg";
import Faq from "./Faq";

const faqList = [
  [
    'What is ParkEagles?',
    'ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out.',
  ],
  [
    'How does ParkEagles work?',
    'Our platform helps you access private parking spaces in a short period. ParkEagles gives an everyday alternative to public garages conveniently and for less money. The combination of free street parking + cheap private parking allows you to save money and be more flexible in your plans.',
  ],
  [
    'How much is cost?',
    'ParkEagles is not the owner of the parking lot, so the price depends on the owners\' request. You can see the cost per hour, per time slot, and special price offers.',
  ],
  [
    'Is ParkEagles charge any fee?',
    'Yes, a service fee is added to each transaction. You can see it before paying.',
  ],
  [
    'What cities is ParkEagles in?',
    'Right now we are filling the base of private parking in New York. Boston, Washington D.C, Philadelphia, Chicago, Los Angeles, San Francisco, Baltimore, Atlanta, Portland, Seattle will be next',
  ],
  [
    'How do I find a spot on private parking?',
    '1. Use Navigation or Parking<br/>a) Navigation. Choose the location where you are going. Receive offers with private parking near arrival address 10 minutes before.<br/>b) Parking. See the list/map of garages around your location.<br/>2. Book / Pay<br/>3. Using our navigation, get to the parking lot<br/>4. Park<br/>5. Find your way back to the car',
  ],
  [
    'How far in advance can I reserve parking?',
    'You can reserve anywhere from minutes in advance; you just need to purchase a reservation before you enter the parking space.',
  ],
  [
    'Is ParkEagles liable for vehicle damage or stolen items?',
    'Because ParkEagles does not own or manage the parking spaces we partner with, ParkEagles is not responsible for theft, damage to your vehicle, or personal property.<br/><br/>Just like any other time you park, please keep your vehicle locked and do not leave valuables in your car when you have a ParkEagles reservation.',
  ],
  [
    'What is a special price for 6-12 hours?',
    'This is a fixed price that you receive if your parked time at the time of payment was 6 to 12 hours.',
  ],
  [
    'What is a special price for 12-24 hours?',
    'This is a fixed price that you receive if your parked time at the time of payment was 12 to 24 hours.',
  ],
  [
    'Is your site secure?',
    'Yes! We have a secure checkout process. All of our billing goes through Stripe, a multi-million dollar company that operates in 20 countries, and we don’t store full credit card information anywhere on our site. Feel free to visit https://stripe.com/ for more information.',
  ],
  [
    'What is green GO (successful parking)?',
    'This indicates the number of successful parking sessions.',
  ],
  [
    'What is red STOP (troubled parking)?',
    'This indicates the number of unsuccessful parking sessions.',
  ],
  [
    'What types of payments does the ParkEagles accept?',
    'ParkEagles accepts all credit/deposit cards as well as ApplePay.',
  ]
];

const missionText = 'We are here to open private parking spaces for drivers as an alternative to public parking. Flexible pricing options allow our users to have a parking spot for a short time at the lowest cost.';

const PrivateParkingPage = () => {
  React.useLayoutEffect(() => window.executeHome(), []);
  return (
    <Layout>
      <MetaTags>
        <title>Garage or Driveway Parking Near Me [$2/hr] [$5/night]</title>
        <meta id="meta-description" name="description" content="Find garage or driveway parking in your neighborhood. [Brooklyn] [NYC]. Hourly, Daily, Overnight parking spaces nearby. Book in minutes. Park 24/7" />
        <link rel="canonical" href="https://parkeagles.com/how-it-works/private" />
      </MetaTags>
      <section className="home two">
        <div className="section-bgr" />
        <div className="section-down" />
        <div className="container">
          <div className="home-wrap">
            <div className="home-text">
              <h1 className="title-lg">Private parking</h1>
              <p className="descr-xl">Don't overpay for monthly rent <br />
                Save money, time & gas<br />
                Open spots near you<br />
                Park on demand
              </p>
              <div className="home-text_wrap">
                <SearchBox placeholder={''} />
              </div>
            </div>
            <div className="home-img">
              <img src={PhoneIcon} alt="phone" />
              <img src={CarIcon} alt="car" />
              <img src={ParkIcon} alt="park" />
            </div>
          </div>
        </div>
      </section>
      <div className="marketplace-mob">
        <div className="container search-box">
          <SearchBox placeholder={''} />
        </div>
      </div>
      <section className="marketplace">
        <div className="container">
          <div className="marketplace-wrap">
            <h2 className="title-xl bg">Hourly, Daily and Overnight Parking on Driveway</h2>
            <ul className="marketplace-list">
              <li>
                <img src={MarketplaceIcon1} alt="marketplace" />
                <h3 className="title-md">No Contract</h3>
              </li>
              <li>
                <img src={MarketplaceIcon2} alt="marketplace" />
                <h3 className="title-md">Best Price</h3>
              </li>
              <li>
                <img src={MarketplaceIcon3} alt="marketplace" />
                <h3 className="title-md">24/7 Access</h3>
              </li>
            </ul>
            <h4 className="title-xl special">Skip Circle the Streets, Park in Minutes</h4>
          </div>
        </div>
      </section>
      <section className="accept">
        <h2 className="title-xl text-center youtube-link">
          <a href='https://www.youtube.com/watch?v=Z4hWFXTauGE' target='_blank'>How It Works</a>
        </h2>
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">1. Look & Accept</h2>
              <p className="descr-lg">Reserve in advance or park instantly<br />
                depending on situations.</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>FlatRate</li>
                <li>HourlyRate</li>
                {/* <li>There</li> */}
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>FlatRate</span>
                  <img src={AcceptIcon1} alt="FlatRate" />
                </div>
                <div className="accept-box">
                  <span>HourlyRate</span>
                  <img src={AcceptIcon2} alt="HourlyRate" />
                </div>
                {/* <div className="accept-box">
                  <span>There</span>
                  <img src={AcceptIcon3} alt="There" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept two">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">2. Book & Pay</h2>
              <p className="descr-lg">Make contactless payments using Visa, Master Card,<br className='desktop-view' />
                Discover, Amex, Maestro, ApplePay, or GooglePay.</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Guaranteed<br /> parking</li>
                <li>Pay as you<br /> go</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Guaranteed<br /> parking</span>
                  <img src={AcceptIcon4} alt="Guaranteed parking" />
                </div>
                <div className="accept-box">
                  <span>Pay as you<br className='desktop-view' /> go</span>
                  <img src={AcceptIcon5} alt="Pay as you go" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">3. Go & Park</h2>
              <p className="descr-lg">Control the complete cycle of the parking <br className='desktop-view' />
                process.</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Built-in <br />
                  navigation</li>
                <li>Parking pass</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Built-in <br />
                    navigation</span>
                  <img src={AcceptIcon6} alt="Built-in navigation" />
                </div>
                <div className="accept-box">
                  <span>Parking pass</span>
                  <img src={AcceptIcon7} alt="Parking pass" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <First garage street text={missionText} />
      <Faq list={faqList} />
    </Layout>
  );
}

export default PrivateParkingPage;


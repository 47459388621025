import React, { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import Layout from '../../components/_common/Layout';
import { database } from "../../utils/firebase";
import { getDuration } from "../../utils/map";
import { Link } from 'react-router-dom';
import ManIcon from '../../assets/img/icons/man.svg';
import GoIcon from '../../assets/img/icons/go.svg';
import StopIcon from '../../assets/img/icons/stop.svg';
import CameraIcon from '../../assets/img/icons/camera.svg';
import ChargeIcon from '../../assets/img/icons/charge.svg';
import OffIcon from '../../assets/img/icons/off.svg';
import OnIcon from '../../assets/img/icons/on.svg';
import MaxIcon from '../../assets/img/icons/max.svg';
import LockIcon from '../../assets/img/icons/locked.svg';
import { ReactComponent as StreetViewIcon } from '../../assets/img/icons/street-view.svg';

const DetailsPage = () => {
  const [place, setPlace] = useState(null);

  useEffect(() => {
    const getPlace = async () => {
      if (localStorage.getItem('currentParking')) {
        const place = JSON.parse(localStorage.getItem('currentParking'));
        getPlaceSigns(place.userId);
        const duration = await getDuration(place);
        setPlace((prevState) => {
          return {
            ...prevState,
            ...place,
            duration
          };
        });
      }
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`;
    script.async = true;
    script.onload = () => {
      getPlace();
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useLayoutEffect(() => {
    if (place?.images.length > 1) {
      // eslint-disable-next-line no-undef
      new Swiper('.detail-slider', {
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        effect: 'fade',
        pagination: {
          el: '.detail-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.detail-arrow_next',
          prevEl: '.detail-arrow_prev',
        },
      });
    }
  }, [place?.images]);

  const getPlaceSigns = (accountId) => {
    return new Promise((resolve, reject) => {
      database.ref(`users`)
        .orderByChild('accountId')
        .equalTo(accountId)
        .limitToFirst(1)
        .on('value', function (snapshot) {
          if (snapshot.val()) {
            const users = Object.entries(snapshot.val());
            const user = users[0][1];
            setPlace((prevState) => {
              return {
                ...prevState,
                user
              };
            });
          }
        }, reject);
    });
  }

  const calculateRate = (rateHistory) => {
    let allReviewCount = 0;
    let positiveReviewCount = 0;
    if (rateHistory) {
      Object.entries(rateHistory).forEach(([, reviews]) => {
        Object.entries(reviews).forEach(([, item]) => {
          const review = Object.entries(item);
          allReviewCount++;
          if (review[0][1].startsWith('ok')) {
            positiveReviewCount++;
          }
        });
      });
    }

    return positiveReviewCount !== 0 ? Math.ceil(5 * (positiveReviewCount / allReviewCount)) : 0;
  };

  const handleStreetView = (geo) => {
    window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${geo.lat},${geo.lon}&pitch=-6.456598406271183&zoom=2.708494148291613&heading=355.7122715565839`);
  }

  if (!place) return null;
  const rate = calculateRate(place?.user?.rateHistory);

  return (
    <Layout>
      <div className="section-two detail">
        <div className="container">
          <div className="detail-wrap">
            <div className="detail-top">
              <a href={`/?map`} className="detail-back">
                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.160311 8.58174L6.9102 1.0831C7.14145 0.825602 7.53645 0.803102 7.79269 1.0356L10.5801 3.53556C10.8376 3.76556 10.8589 4.16055 10.6289 4.41805L8.76143 6.50052H19.375C19.72 6.50052 20 6.78051 20 7.12551V10.8755C20 11.2204 19.72 11.5004 19.375 11.5004H8.76143L10.6276 13.5842C10.8576 13.8417 10.8364 14.2354 10.5789 14.4666L7.79144 16.9666C7.67269 17.0729 7.52394 17.1254 7.3752 17.1254C7.20395 17.1254 7.03395 17.0554 6.9102 16.9179L0.160311 9.41797C-0.0534363 9.18048 -0.0534363 8.81923 0.160311 8.58174ZM7.42145 15.6179L9.27892 13.9517L6.89645 11.2917C6.73271 11.1079 6.69021 10.8455 6.79146 10.6192C6.8927 10.3955 7.11645 10.2505 7.3627 10.2505H18.75V7.7505H7.3627C7.11645 7.7505 6.8927 7.6055 6.79146 7.3805C6.69021 7.15551 6.73271 6.89176 6.89645 6.70801L9.28017 4.0493L7.4227 2.38308L1.46654 9.00048L7.42145 15.6179Z"
                    fill="#4DA1FF" />
                </svg>
                Back
              </a>
              {place.type !== 'charges' && <span className="detail-price">
                $ {place.kilowatts ? `${place.kilowatts}/kw` : place.price ? `${Math.round(parseFloat(place.price))}/hr` : ''}
              </span>}
            </div>
            <div className="detail-contain">
              <div className="swiper-container detail-slider">
                <div className="swiper-wrapper">
                  {place?.images.map((img) => <div className="swiper-slide detail-slide" key={img}>
                    <img src={img} alt="photo" />
                  </div>)}
                </div>
                {place.images?.length > 1 && <>
                  <div className="detail-pagination" />
                  <div className="detail-arrows">
                    <svg className="detail-arrow detail-arrow_prev" width="20" height="30" viewBox="0 0 20 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M2.02666 11.2688L11.7236 1.45056C13.6121 -0.46399 16.6909 -0.46399 18.5794 1.45056C19.4958 2.37593 20 3.61057 20 4.92131C20 6.23204 19.4958 7.46668 18.5794 8.39205L12.3103 14.7395L18.5794 21.087C19.4958 22.0148 20 23.247 20 24.5578C20 25.8685 19.4958 27.1007 18.5794 28.0285C17.663 28.9563 16.4461 29.4669 15.1515 29.4669C13.857 29.4669 12.64 28.9563 11.7236 28.0285L2.02666 18.2103C1.1103 17.2849 0.60606 16.0503 0.60606 14.7395C0.60606 13.4288 1.1103 12.1942 2.02666 11.2688Z" />
                    </svg>
                    <svg className="detail-arrow detail-arrow_next" width="20" height="30" viewBox="0 0 20 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M17.9733 11.2688L8.27636 1.45056C6.38788 -0.46399 3.30909 -0.46399 1.42061 1.45056C0.504243 2.37593 0 3.61057 0 4.92131C0 6.23204 0.504243 7.46668 1.42061 8.39205L7.6897 14.7395L1.42061 21.087C0.504243 22.0148 0 23.247 0 24.5578C0 25.8685 0.504243 27.1007 1.42061 28.0285C2.33697 28.9563 3.55394 29.4669 4.84848 29.4669C6.14303 29.4669 7.36 28.9563 8.27636 28.0285L17.9733 18.2103C18.8897 17.2849 19.3939 16.0503 19.3939 14.7395C19.3939 13.4288 18.8897 12.1942 17.9733 11.2688Z" />
                    </svg>
                  </div>
                </>}
              </div>
              <div className="detail-text">
                <h3>{place.name}</h3>
                <ul className="detail-rating">
                  {[1, 2, 3, 4, 5].map((index) =>
                    <li key={`rate_${index}`} className={rate >= index ? "active" : undefined} />
                  )}
                </ul>
                <p>{place.address}</p>
                {place.phone && <p>Phone: {place.phone}</p>}
                {place.parking_type_name && <p>Parking: {place.parking_type_name}</p>}
                {place.cost_description && place.cost_description !== 'Unknown' && <p>Cost: {place.cost_description}</p>}
                {place.model && <p>Plugs type: {place.model}</p>}
                {place.hours && place.hours !== '-' && <p>Hours Open: {place.hours}</p>}
                {place.duration && <span><img src={ManIcon} alt="icon" />To the point: <b>{place.duration} min.</b></span>}
                <a href="/download" className="button">Download App & {place.type === 'charges' ? 'Go' : 'Park'}</a>
                <a className="button button-street-view" onClick={() => handleStreetView(place)}>
                  <StreetViewIcon />
                  <span>
                    Street view
                  </span>
                </a>
              </div>
            </div>
            {!["garage", "charges"].includes(place.type) && <div className="detail-signs">
              <div className="detail-signs_box one">
                <div className="detail-signs_top">
                  <img src={GoIcon} alt="icon" />
                  <b>{place.user?.greenRating || 0}</b>
                </div>
                <span className="green">successful parking</span>
              </div>
              <div className="detail-signs_box one">
                <div className="detail-signs_top">
                  <img src={StopIcon} alt="icon" />
                  <b>{place.user?.redRating || 0}</b>
                </div>
                <span className="red">troubled parking</span>
              </div>
              <div className="detail-signs_box two">
                <ul>
                  {Array.isArray(place.spotFeatures) && place.spotFeatures.includes('1') && <li>
                    <img src={CameraIcon} alt="icon" />
                    <span>camera</span>
                  </li>}
                  {Array.isArray(place.spotFeatures) && place.spotFeatures.includes('2') && <li>
                    <img src={LockIcon} alt="icon" />
                    <span>lock</span>
                  </li>}
                  {Array.isArray(place.spotFeatures) && place.spotFeatures.includes('3') && <li>
                    <img src={ChargeIcon} alt="icon" />
                    <span>EV charging</span>
                  </li>}
                </ul>
              </div>
              <div className="detail-signs_box two">
                <div className="detail-signs_top">
                  <img src={(place.spec1 || place.spec2) ? OffIcon : OnIcon} alt="icon" />
                </div>
                <span>
                  {(place.spec1 || place.spec2) ? <>
                    {place.spec1 ? `${place.spec1} (6-12 hrs)` : ''}
                    <br />
                    {place.spec2 ? `${place.spec2} (12-24 hrs)` : ''}
                  </> : "No Special Price"}
                </span>
              </div>
              {place.endDate && <div className="detail-signs_box two">
                <div className="detail-signs_top">
                  <img src={MaxIcon} alt="icon" />
                </div>
                <span>{moment(place.endDate).format('MMMM Do, HH:mm A')}</span>
              </div>}
            </div>}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default DetailsPage;


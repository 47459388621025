import { combineReducers } from 'redux'
import {
    SET_UID,
    SET_MENU,
    SET_USER_DATA,
    SET_PARKING_DATA,
    SET_BANK_DATA,
    SET_REQUIREMENTS_DATA,
    SET_COMPANY_STATUS,
    SET_NOTIFICATIONS_DATA,
    SET_NOTIFICATIONS_COUNTER,
    SET_NOTIFICATIONS_REQ,
} from './actions'

const uidState = {
    uid: undefined,
};

const uidReducer = (state = uidState, action) => {
    switch (action.type) {
        case SET_UID:
            return {
                ...state,
                uid: action.uid
            };

        default:
            return state;
    }
};

const menuState = {
    openMenu: false,
};

const menuReducer = (state = menuState, action) => {
    switch (action.type) {
        case SET_MENU:
            return {
                ...state,
                openMenu: action.menu
            };

        default:
            return state;
    }
};

const userState = {
    userData: null,
};

const userReducer = (state = userState, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.userData,
            };

        default:
            return state;
    }
};

const parkingState = {
    parkingData: null,
};

const parkingReducer = (state = parkingState, action) => {
    switch (action.type) {
        case SET_PARKING_DATA:
            return {
                ...state,
                parkingData: action.parkingData,
            };

        default:
            return state;
    }
};

const bankState = {
    bankData: null,
};

const bankReducer = (state = bankState, action) => {
    switch (action.type) {
        case SET_BANK_DATA:
            return {
                ...state,
                bankData: action.bankData,
            };

        default:
            return state;
    }
};

const requirementsState = {
    requirementsData: null,
};

const requirementsReducer = (state = requirementsState, action) => {
    switch (action.type) {
        case SET_REQUIREMENTS_DATA:
            return {
                ...state,
                requirementsData: action.requirementsData,
            };

        default:
            return state;
    }
};


const companyStatusState = {
    status: '',
};

const companyStatusReducer = (state = companyStatusState, action) => {
    switch (action.type) {
        case SET_COMPANY_STATUS:
            return {
                ...state,
                status: action.status,
            };

        default:
            return state;
    }
};

const notificationsState = {
    notificationsCounter: 0,
    notificationsData: null,
    notificationsReq: null
};

const notificationsReducer = (state = notificationsState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS_DATA:
            return {
                ...state,
                notificationsData: action.notificationsData,
            };

        case SET_NOTIFICATIONS_COUNTER:
            return {
                ...state,
                notificationsCounter: action.notificationsCounter,
            };

        case SET_NOTIFICATIONS_REQ:
            return {
                ...state,
                notificationsReq: action.notificationsReq,
            };    

        default:
            return state;
    }
};



const reducers = combineReducers({
    uidReducer,
    menuReducer,
    userReducer,
    parkingReducer,
    bankReducer,
    requirementsReducer,
    companyStatusReducer,
    notificationsReducer
})

export default reducers;

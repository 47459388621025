import React from 'react';
import { useHistory } from 'react-router-dom';
import MapIcon from '../../assets/img/icons/man.svg';

const Duration = ({duration}) => {
  return <span><img src={MapIcon} alt="icon"/>To the point: <b>{duration} min</b></span>;
};

const MapSingle = ({place, duration, showDetails = true}) => {
  const history = useHistory();

  return <div className="map-point">
    <div className="map-point_img">
      {place.img && <img src={place.img} alt="photo"/>}
    </div>
    <div className="map-point_text">
      <h3>{place.name}</h3>
      <p>{place.address}</p>
      <Duration duration={duration}/>
    </div>
    <div className="map-point_price">
      {place.type !== 'charges' && place.price ?  <span>$ {Math.round(parseFloat(place.price))}/hr</span> : <span />}
      {showDetails && <a
        href="#"
        onClick={() => {
          localStorage.setItem('currentParking', JSON.stringify(place));
          history.push(`/map/${place.id}`);
        }}
        className="button"
      >
        Details
      </a>}
    </div>
  </div>;
}

export default MapSingle;


import React from 'react';
import Layout from '../../components/_common/Layout';
import Download from '../../components/Download';

const DownloadPage = () => <Layout>
  <Download />
</Layout>;

export default DownloadPage;


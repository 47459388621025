import React from 'react';
import {useLocation} from "react-router";
import Layout from '../../components/_common/Layout';
import Header from '../../components/SpanceList/Header';
import Marketplace from '../../components/_common/Marketplace';
import Accept from '../../components/SpanceList/Accept';
import Comments from '../../components/SpanceList/Comments';
import Faq from '../../components/_common/Faq';
import SecondMission from '../../components/_common/Mission/Second';

const faqList = [
  [
    'What is ParkEagles?',
    'ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out.',
  ],
  [
    'How does ParkEagles work?',
    'Owners/holders of the private parking lot share the space on a short-term basis. To do this, they publish parking data, weekly schedules, and prices per hour or a flat rate. Drivers see this offer on the ParkEagles website/app, pay online and park. The host will be notified when the driver has paid, arrived, and left the parking space. The weekly schedule is subject to change at any time, except for this active parking session.',
  ],
  [
    'How do I list my parking spot on ParkEagles?',
    'We’re always happy to help you get your spot listed on ParkEagles. You can list your spot with us here.<br/>Use the mobile or desktop version of the service.<br/>1. Introduction Parking Lot Location<br/>2. Add a sample parking lot and proof that you are the owner/landlord.<br/>3. Select parking data a) type b) capacity c) size<br/>4. Add features and description<br/>5. Make a weekly schedule with prices<br/>6. Add bank details for withdrawing money"',
  ],
  [
    'Do I have to download the ParkEagles app?',
    'Nope! However, if you want to access all the features of our service as a driver, download the app and get all the features in one place.',
  ],
  [
    'How much should I charge for my space?',
    'We recommend starting from the following factors affecting the cost:<br/>1. Location<br/>2. The cost of the monthly rental of private parking in your country<br/>3. Time of day/season<br/>4. Weather conditions<br/>Use special offers to boost demand. For example, offers with night slots and a fixed price are very common.',
  ],
  [
    'How and when do I get paid?',
    'Each host registers a bank account for withdrawing money. Earnings are paid every Monday (with a balance of at least $10)',
  ],
  [
    'Do I have to pay to rent out my parking space?',
    'To keep the online community safe and secure for all users, ParkEagles charges hosts a 10% service fee.',
  ],
  [
    'What if I need to use the space that a renter is occupying?',
    'Hosts have the right to cancel the reservation before the driver arrives (it will be reflected in the history and will negatively affect the rating). If the user is already parked and the host wants the space to be vacated, we recommend contacting personally and discussing it.',
  ],
  [
    'How much can I potentially earn?',
    'The amount of income that you can earn from your listing will vary depending on your location, availability, and number of spaces.',
  ],
  [
    'Can I list a space if I rent, rather than own, my property?',
    'Yes, you can. Both property owners and renters are free to list their parking spaces with ParkEagles. However, if you are a renter, please review your rental agreement and speak with your landlord before publishing your listing. If you have created a listing on rented property, we assume that you have taken the proper steps and obtained approval from the property manager or owner.',
  ],
  [
    'Should I charge a flat rate or an hourly rate?',
    'In order to accommodate all kinds of listings, ParkEagles offers two different pricing options. As a Host, you can choose to charge a variable rate, which means you set an hourly price and Drivers pay based on the length of their reservation, or you can charge a flat rate, which means Drivers will pay the same amount regardless of how many hours they reserve.',
  ],
  [
    'What if a Guest leaves their car in my driveway and the reservation has ended?',
    'ParkEagles sends an alert to Drivers when their reservation is almost over. It also gives Drivers the option to extend the reservation if your listing schedule allows. If your Driver’s vehicle is still in your space after their reservation has ended, you can contact them through our system. If they don’t reply and move their vehicle promptly, you can also reach out to Help Center and we will help you determine the best course of action from there.',
  ],
  [
    'Can I edit my listing while it\'s reserved?',
    'While the option to edit your listing is given for reserved listings, any saved changes will not take effect until the current reservation ends. Once a reservation starts, the listing serves as a contract between the host and renter and cannot be changed.',
  ]
];

const scrollToSmoothly = (pos, time = 500) => {
  let currentPos = window.pageYOffset;
  let start = null;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
    } else {
      window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
}

const SpaceListPage = () => {
  const location = useLocation();

  React.useLayoutEffect(() => window.executeHome(), []);
  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (location.pathname.includes('list-your-space')) {
        const element = document.getElementById("list-your-space");
        scrollToSmoothly(element.offsetTop);
      } else if (location.pathname.includes('сustomize-list')) {
        const element = document.getElementById("сustomize-list");
        scrollToSmoothly(element.offsetTop);
      } else if (location.pathname.includes('private-parking-process')) {
        const element = document.getElementById("private-parking-process");
        scrollToSmoothly(element.offsetTop);
      } else if (location.pathname.includes('hosts-service-fee')) {
        const element = document.getElementById("hosts-service-fee");
        scrollToSmoothly(element.offsetTop);
      }
    }, 500);
  }, []);
  return (
    <Layout>
      <Header/>
      <Marketplace/>
      <Accept/>
      <Comments/>
      <Faq list={faqList} />
      <SecondMission/>
    </Layout>
  );
}

export default SpaceListPage;


import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { useLocation } from 'react-router';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import parkingSchedule from '../../utils/parkingSchedule.json';
import MapParkingSchedule from '../../utils/mapParkingSchedule';

import { database } from "../../utils/firebase";
import { getDistance } from "../../utils/map";

import Layout from '../../components/_common/Layout';
import Map from '../../components/_common/UI/Map/Map';
import List from '../../components/Map/List';
import Form from '../../components/Map/Form';
import CitiesList from '../../components/Map/CitiesList';

import { ReactComponent as FreeParkingIcon } from '../../assets/img/icons/free-parking.svg';
import { ReactComponent as NoParkingIcon } from '../../assets/img/icons/no-parking.svg';
import { ReactComponent as MeterHourParkingIcon } from '../../assets/img/icons/meter-hour-parking.svg';
import { ReactComponent as NoParkingAnytimeIcon } from '../../assets/img/icons/no-parking-anytime.svg';

const hashCode = (str) => {
  let hash = 0;
  if (!str.length) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash;
}

const MAX_DISTANCE_IN_MAP = 2;

const MapPage = ({ data }) => {
  const locationFromURL = useLocation();
  const queryTab = window.location.search.slice(1, window.location.search.length);
  const [address, setAddress] = useState(data?.address);
  const [tab, setTab] = useState(queryTab);
  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState({
    center: [40.196690800631444, -98.89111616191042],
    lat: 40.196690800631444,
    lng: -98.89111616191042,
    zoom: 2,
    isDefault: true
  });

  const {
    placePredictions,
    getPlacePredictions,
    placesService
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  useEffect(() => {
    if (placePredictions.length) {
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        ({ geometry, formatted_address }) => {
          const lat = geometry.location.lat();
          const lng = geometry.location.lng();
          const location = {
            address: formatted_address,
            center: [lat, lng],
            lat,
            lng,
            isDefault: true,
            zoom: 15
          };

          setLocationLS(location);
        }
      );
    }
  }, [placePredictions])

  useEffect(() => {
    const values = window.localStorage.getItem('location');

    getPlacePredictions({ input: locationFromURL.pathname.replace('/', ' ').replace('-', ' ') });

    if (!locationFromURL.search.length) {
      window.location.href = `${locationFromURL.pathname}?map`
    }

    if (values) {
      const data = JSON.parse(values);
      if (data) {
        setLocation(data);
        setAddress(data.address);
      }
    }
  }, []);

  const setLocationLS = (location) => {
    localStorage.setItem('location', JSON.stringify(location));
    setLocation(location);
    setAddress(location.address);
  };

  const [types, setTypes] = useState([]);

  useEffect(() => {
    const values = window.localStorage.getItem('types');

    if (values) {
      setTypes(JSON.parse(values));
    }
  }, []);

  const [places, setPlaces] = useState([]);
  const [mapApi, setMapApi] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);

  const getStreetParking = (cb) => {
    const startDate = moment().unix();
    database.ref(`ColorAnnotationsData/`)
      .orderByChild("date").startAt(startDate)
      .on('child_added', (snapshot) => {
        const data = snapshot.val();
        if ((startDate - moment(data.date).unix()) <= 300) {
          cb(data);
        }
      });
  }
  const getPrivateParking = () => {
    return new Promise((resolve, reject) => {
      database.ref(`PrivateParking/`)
        .orderByChild('active')
        .equalTo('1')
        .on('value', function (snapshot) {
          let results = [];
          if (snapshot.val()) {
            results = Object.values(snapshot.val()).map((item) => {
              return {
                id: hashCode(item.accountId.toString()),
                type: 'private',
                lat: item.lat,
                lon: item.lon,
                img: item.img,
                images: item.gallery?.length > 0 ? item.gallery : [item.img],
                address: item.streetName,
                name: item.type,
                price: item.price?.replace('$', ''),
                distance: 0,
                spotFeatures: item.spotFeatures ? JSON.parse(item.spotFeatures) : [],
                spec1: item.spec1,
                spec2: item.spec2,
                endDate: item.endDate,
                userId: item.accountId,
              }
            });
          }

          resolve(results);
        }, reject);
    });
  }
  const getGarages = () => {
    return axios.get('https://parknewtech.com/park_db/getAll.php')
      .then((data) => {
        return data.data.map(item => {
          return {
            ...item,
            name: 'Garage',
            id: hashCode(item.id.toString()),
            images: [item.img || '/img/charge.svg'],
            img: item.img || '/img/charge.svg',
            distance: 0,
            type: 'garage',
            userId: null,
            price: item.price || ''
          };
        });
      });
  }

  const getElCharges = () => {
    return axios.get('https://parkeagles.com/ev_charges/getAll.php')
      .then((data) => {
        return data.data.map(item => {
          return {
            ...item,
            name: 'Charging station',
            id: hashCode(`${item.longitude.toString()}-${item.latitude.toString()}`),
            lat: item.latitude,
            lon: item.longitude,
            images: item.photo ? [item.photo] : [],
            img: item.photo ? item.photo : null,
            price: item.kilowatts,
            distance: 0,
            type: 'charges',
            userId: null
          };
        });
      });
  }

  useEffect(() => {
    const fetchParkingPlaces = async () => {
      setLoading(true);
      try {
        const [privates, garages, charges] = await Promise.all([
          getPrivateParking(),
          getGarages(),
          getElCharges()
        ]);
        setPlaces((prevState) => {
          const items = _.cloneDeep(prevState);
          const data = privates.concat(garages);
          return items.concat(data).concat(charges);
        });
      } catch (e) { }
      setLoading(false);
    };
    if (!location.isDefault) {
      fetchParkingPlaces();
    } else {
      setLoading(false);
    }
  }, [location.isDefault]);

  useEffect(() => {
    getStreetParking((data) => {
      setPlaces((prevState) => {
        const items = _.cloneDeep(prevState);
        items.push({
          ...data,
          type: 'street',
        });
        return items;
      });
    });
  }, []);

  const handleTabClick = (tab) => {
    window.location.search = `?${tab}`;
    setTab(tab);
  };

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map);
    setMapApi(maps);
    setMapApiLoaded(true);

    if (tab === 'alt-parking') {
      const mapParkingSchedule = new MapParkingSchedule({ map, maps, parkingSchedule });

      maps.event.addListener(map, 'zoom_changed', () => {
        const zoom = map.getZoom();

        if (zoom >= 17) {
          mapParkingSchedule.render();
        } else {
          mapParkingSchedule.clean();
        }
      });

      maps.event.addListener(map, 'dragend', () => {
        const zoom = map.getZoom();

        if (zoom >= 17) {
          mapParkingSchedule.render();
        } else {
          mapParkingSchedule.clean();
        }
      });
    }
  };

  const items = places
    .filter((place) => getDistance(place.lat, place.lon, location.lat, location.lng) <= MAX_DISTANCE_IN_MAP)
    .filter(place => types.includes(place.type));

  return (
    <Layout>
      <MetaTags>
        <title>[ParkEagles] #1 Parking Assistant For Every Day* Promo [1FREE]</title>
        <meta id="meta-description" name="description" content="Find Parking [Near Free Cheap Street Garages Private Driveway Lot Discount] with ParkEagles. Book & Pay Online Best Parking Deals at Lowest Price. [50%OFF]" />
        <link rel="canonical" href="https://parkeagles.com" />
      </MetaTags>
      <div className="section-two map">
        <div className="container">
          <div className="map-wrap">
            <ul className="map-tabs">
              <li className={queryTab === 'map' ? 'active' : ''} onClick={() => handleTabClick('map')}>Map</li>
              <li className={queryTab === 'list' ? 'active' : ''} onClick={() => handleTabClick('list')}>List</li>
              <li className={queryTab === 'alt-parking' ? 'active' : ''} onClick={() => handleTabClick('alt-parking')}>Alternative side parking</li>
            </ul>
            {mapApiLoaded && <>
              {tab === 'map'
                && <Form
                  mapInstance={mapInstance}
                  mapApi={mapApi}
                  address={address}
                  setAddress={setAddress}
                  changeLocation={(data) => {
                    setLocation(data);
                    window.localStorage.setItem('location', JSON.stringify(data));
                  }}
                  types={types}
                  setTypes={(value) => {
                    setTypes(value);
                    window.localStorage.setItem('types', JSON.stringify(value));
                  }}
                />}
              {tab === 'alt-parking' && <div className='alt-parking-container'>
                <div>
                  <FreeParkingIcon />
                  <span className='alt-parking-icon-text'> - free of charge</span>
                </div>
                <div>
                  <MeterHourParkingIcon />
                  <span className='alt-parking-icon-text'> - meter</span>
                </div>
                <div>
                  <NoParkingIcon />
                  <span className='alt-parking-icon-text'> - no parking now</span>
                </div>
                <div>
                  <NoParkingAnytimeIcon />
                  <span className='alt-parking-icon-text'> - no parking anytime</span>
                </div>
              </div>
              }
            </>}
            {tab === 'alt-parking' &&
              <div className='map-info map-info-alt-parking'>
                <Map
                  address={address}
                  location={location}
                  places={items}
                  apiHasLoaded={apiHasLoaded}
                  map={mapInstance}
                  mapApi={mapApi}
                  types={types}
                  setAddress={setAddress}
                  tab={tab}
                />
              </div>
            }
            {(tab === 'map' || tab === 'list') &&
              <div className='map-info'>
                <Map
                  address={address}
                  location={location}
                  places={items}
                  apiHasLoaded={apiHasLoaded}
                  map={mapInstance}
                  mapApi={mapApi}
                  types={types}
                  setAddress={setAddress}
                  tab={tab}
                />
                <List
                  places={items.filter((place) => place.type !== 'street')}
                  tab={tab}
                />
              </div>
            }
          </div>
          <CitiesList tab={tab} />
        </div>
      </div>
    </Layout>
  );
}

export default MapPage;


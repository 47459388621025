import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import PrivateMarker from './Marker/Private';
import ChargeMarker from './Marker/Charge';
import LocationMarker from './Marker/Location';
import StreetMarker from './Marker/Street';
import MapSingle from '../../../Map/MapSingle';
import { getDuration } from "../../../../utils/map";

const Map = ({ location, places, apiHasLoaded, types, tab }) => {
  const history = useHistory();
  const [draggable] = useState(true);
  const [place, setPlace] = useState(null);
  const [items, setItems] = useState([]);
  useEffect(() => {
    setItems(items);
  }, [places]);

  const onChange = ({ center, zoom }) => {
    // setZoom(zoom);
    // setCenter(center);
  }

  const Markers = (data) => data
    .filter(place => types.includes(place.type))
    .map(place => {
      if (place.type === 'street') {
        return <StreetMarker
          key={`map-${place.id}-${place.lat}-${place.lon}`}
          view={types.includes(place.type)}
          lat={parseFloat(place.lat)}
          lng={parseFloat(place.lon)}
          place={place}
          onHover={setPlace}
        />
      }
      if (place.type === 'charges') {
        return <ChargeMarker
          key={`map-${place.id}-${place.lat}-${place.lon}`}
          view={types.includes(place.type)}
          onClick={() => {
            localStorage.setItem('currentParking', JSON.stringify(place));
            history.push(`/map/${place.id}`);
          }}
          lat={parseFloat(place.lat)}
          lng={parseFloat(place.lon)}
          place={place}
          onHover={setPlace}
        />
      }
      return <PrivateMarker
        key={`map-${place.id}-${place.lat}-${place.lon}`}
        view={types.includes(place.type)}
        onClick={() => {
          localStorage.setItem('currentParking', JSON.stringify(place));
          history.push(`/map/${place.id}`);
        }}
        lat={parseFloat(place.lat)}
        lng={parseFloat(place.lon)}
        place={place}
        onHover={setPlace}
      />
    });
  return (
    <div className={`map-info_wrap ${tab === 'alt-parking' || tab === 'map' ? 'active' : ''}`}>
      <div className='google-maps-wrapper'>
        <div className='google-maps'>
          <GoogleMapReact
            center={location.center}
            zoom={location.zoom}
            draggable={draggable}
            onChange={onChange}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
              libraries: ['places', 'geometry'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
            LoadingContainer={<div style={{ background: 'white' }} />}
          >
            {tab === 'map' && Markers(places)}
            <LocationMarker
              key={`location`}
              text={'Current user location'}
              lat={parseFloat(location.lat)}
              lng={parseFloat(location.lng)}
              view={!location.isDefault}
            />
          </GoogleMapReact>
        </div>
      </div>
      {place && <MapSingle place={place} duration={getDuration(place)} showDetails={false} />}
    </div>
  );
};

export default Map;

import FreeParking from '../assets/img/icons/free-parking.png';
import NoParking from '../assets/img/icons/no-parking.png';
import MeterHourParking from '../assets/img/icons/meter-hour-parking.png';
import NoParkingAnytime from '../assets/img/icons/no-parking-anytime.png';

export default class MapParkingSchedule {
  constructor({ map, maps, parkingSchedule }) {
    this.map = map;
    this.maps = maps;
    this.parkingSchedule = parkingSchedule;
    this.polygons = [];
    this.markers = [];
    this.week = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.infowindow = new this.maps.InfoWindow();
  }

  clean() {
    this.polygons.forEach((polygon) => {
      polygon.setMap(null);
    });

    this.markers.forEach((marker) => {
      marker.setMap(null);
    });

    this.polygons = [];
    this.markers = [];
  };

  getPlaceType({ place, currentTime }) {
    let type = '';
    let segmentName = 'No';

    if (place.schedule.length < 3) {
      place.schedule?.forEach((segment) => {
        const startTime = parseInt(segment.start_date.match(/T(\d{2}):\d{2}/)[1]);
        const endTime = parseInt(segment.end_date.match(/T(\d{2}):\d{2}/)[1]);
        const startDay = parseInt(segment.start_date.match(/^\d{4}-\d{2}-(\d{2})/)[1]);
        const endDay = parseInt(segment.end_date.match(/^\d{4}-\d{2}-(\d{2})/)[1]);

        if (place.schedule.length === 1 && segment.npa && segment.name === 'NoParking') {
          segmentName = 'No-negative';
        } else if (startTime <= currentTime.hour && endTime >= currentTime.hour || (startTime === 0 && endTime === 0)) {
          const currentDayOrder = this.week.indexOf(currentTime.day) + 1;

          if ((startDay <= currentDayOrder && endDay >= currentDayOrder) || (startDay === 1 && endDay === 1)) {
            segmentName = segment.name;
          } else {
            segmentName = 'No-negative';
          }
        }

        type += segment.name;
      });
    }

    return { type, segmentName };
  };

  drawPolygon({ paths, strokeColor, fillColor }) {
    const polygon = new this.maps.Polygon({
      paths,
      strokeColor,
      strokeOpacity: 0.5,
      strokeWeight: 5,
      fillColor,
      fillOpacity: 0.5,
    });

    polygon.setMap(this.map);
    this.polygons.push(polygon);
  }

  convertTimeFormat(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }

    return time.join('');
  }

  getFormattedPeriod({ start, end }) {
    const startTime = this.convertTimeFormat(start.match(/T(\d{2}:\d{2})/)[1]);
    const endTime =  this.convertTimeFormat(end.match(/T(\d{2}:\d{2})/)[1]);
    const startDay = start.match(/^\d{4}-\d{2}-(\d{2})/)[1];
    const endDay = end.match(/^\d{4}-\d{2}-(\d{2})/)[1];
    const startWeekDay = this.week[parseInt(startDay) - 1];
    const endWeekDay = this.week[parseInt(endDay) - 1];

    return startWeekDay === endWeekDay && startWeekDay === 'Mon' && endWeekDay === 'Mon'
      ? `All week days ${startTime} - ${endTime}`
      : startWeekDay === endWeekDay 
        ? `${startWeekDay} ${startTime} - ${endTime}`
        : `${startWeekDay} - ${endWeekDay} ${startTime} - ${endTime}`;
  }

  getFormattedPeriodName(name) {
    return name.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  drawMarker({ title, placeCoords, icon, label, popup, price, duration, placeSchedule, id }) {
    const markerType = title === 'No Parking' 
      ? (placeSchedule.length === 1 && placeSchedule[0].npa ? 'red' : 'gray')
      : (title === 'Free' ? 'green' : '' );
    const isShowPrice = placeSchedule.find(({ name }) => name === 'MeterHour');
    const bounds = new this.maps.LatLngBounds();

    for (let i = 0; i < placeCoords.length; i++) {
      bounds.extend(placeCoords[i]);
    }

    const center = bounds.getCenter();

    const marker = new this.maps.Marker({
      position: { lat: center.lat() - (title === 'Free' ? 0.00002 : 0), lng: center.lng() },
      map: this.map,
      icon,
      label,
      optimized: true,
    });

    this.markers.push(marker);

    if (popup) {
      const contentString = `
        <div class='alt-parking-popup-top'>
          <div class='alt-parking-popup-top-title ${markerType}'>${title}</div>
          ${isShowPrice ? `<div class='alt-parking-popup-top-price'>${price || ' - '}</div>` : ''}
        </div>
        <div class='alt-parking-popup-content'>
          <div class='alt-parking-popup-content-schedule'>
            ${markerType === 'red' 
              ? `
                <div class='alt-parking-popup-content-schedule-item'>
                  <div class='alt-parking-popup-content-schedule-title'>No parking any time</div>
                  <div class='alt-parking-popup-content-schedule-text'>Mon to Sun &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All day</div>
                </div>
              `
              : ``
            }
            ${markerType !== 'red'
              ? `
                ${placeSchedule.map((period) => {
                  const formattedPeriod = this.getFormattedPeriod({ start: period.start_date, end: period.end_date });
          
                  return `
                          <div class='alt-parking-popup-content-schedule-item'>
                            <div class='alt-parking-popup-content-schedule-title'>${this.getFormattedPeriodName(period.name)}</div>
                            <div class='alt-parking-popup-content-schedule-text'>${formattedPeriod}</div>
                          </div>
                        `
                })}
              `
              : ``
            }
          </div> 
          ${isShowPrice
            ? `
              <div class='alt-parking-popup-content-duration'>
                ${duration / 60 || ' - '}hr max
              </div>
            `
            : ``
          }
        </div>
        <div class='alt-parking-popup-bottom'>
          <a onclick='window.open("https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${center.lat() - 0.00008},${center.lng()}&pitch=-6.456598406271183&zoom=2.708494148291613&heading=355.7122715565839")' class='button button-street-view'>
            <span>Street view</span>
          </a>
        </div>
      `;

      this.maps.event.addListener(marker, 'click', () => {
        this.infowindow.setContent(contentString);
        this.infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
        // console.log({ id })
      });
    }
  }

  draw({ markerType, placeCoords, leftHours, leftDays, durationHours, placeSchedule, id }) {
    switch (markerType) {
      case 'StreetCleaning':
        this.drawPolygon({ paths: placeCoords, strokeColor: '#7F8FA4', fillColor: '#7F8FA4' });
        this.drawMarker({ title: 'Street Cleaning', placeCoords, icon: NoParking, popup: true, placeSchedule  });
        break;
      case 'Free':
        this.drawPolygon({ paths: placeCoords, strokeColor: '#1BCC57', fillColor: '#1BCC57' });
        let label;

        if (leftHours || leftDays) {
          label = {
            color: '#1BCC57',
            fontWeight: 'bold',
            text: `${leftDays ? `${leftDays.toString()} ${leftDays > 1 ? 'days' : 'day'}` : leftHours ? `${leftHours.toString()}h` : ''}`,
            fontSize: '14px',
          };
        }

        this.drawMarker({
          title: 'Free',
          placeCoords,
          icon: { url: FreeParking },
          label,
          popup: true,
          placeSchedule, 
          id
        });
        break;
      case 'NoParking':
        this.drawPolygon({ paths: placeCoords, strokeColor: '#7F8FA4', fillColor: '#7F8FA4' });
        this.drawMarker({ title: 'No Parking', placeCoords, icon: NoParking, popup: true, placeSchedule, id });
        break;
      case 'NoParkingAnytime':
        this.drawPolygon({ paths: placeCoords, strokeColor: '#d3e0f2', fillColor: '#d3e0f2' });
        this.drawMarker({ title: 'No Parking', placeCoords, icon: NoParkingAnytime, popup: true, placeSchedule });
        break;
      case 'MeterHour':
        this.drawPolygon({ paths: placeCoords, strokeColor: '#4DA1FF', fillColor: '#4DA1FF' });
        this.drawMarker({ title: 'Meter', placeCoords, icon: MeterHourParking, popup: true, price: '$ 1.5/hr', duration: durationHours, placeSchedule });
        break;
      case 'TimeLimit': // TODO: check for limit and show it
        this.drawPolygon({ paths: placeCoords, strokeColor: '#4DA1FF', fillColor: '#4DA1FF' });
        this.drawMarker({ placeCoords, icon: MeterHourParking });
        break;
    }
  };

  countLeftHours({ currentTime, place, to }) {
    const suppression = place.schedule.find((i) => i.name === to);
    const startHour = parseInt(suppression.start_date.match(/T(\d{2}):\d{2}/)[1]);
    const endHour = parseInt(suppression.end_date.match(/T(\d{2}):\d{2}/)[1]);
    const startDay = parseInt(suppression.start_date.match(/^\d{4}-\d{2}-(\d{2})/)[1]);
    const endDay = parseInt(suppression.end_date.match(/^\d{4}-\d{2}-(\d{2})/)[1]);

    let leftHours = null;
    let leftDays = null

    if (endDay !== 1 && startDay !== endDay) {
      const currentDayOrder = this.week.indexOf(currentTime.day) + 1;

      if (currentDayOrder < startDay && startDay - currentDayOrder > 1) {
        leftDays = startDay - currentDayOrder;
      }

      if (currentDayOrder > startDay) {
        leftDays = 6 - currentDayOrder + startDay;
      }
    }

    if (endHour < currentTime.hour) {
      leftHours = 24 - currentTime.hour + startHour;

      if (leftHours > 24) {
        leftHours = leftHours - 24;
        leftDays = leftDays + 1;
      }
    } else {
      leftHours = startHour - currentTime.hour;

      if (leftHours > 24) {
        leftHours = leftHours - 24;
        leftDays = leftDays + 1;
      }
    }

    return { leftDays, leftHours };
  };

  getDuration(place) {
    const duration = place.schedule.find((i) => i.name === 'MeterHour' && i.duration);

    if (duration) {
      return duration.duration;
    }

    return '--';
  }

  // TODO:
  // - add lazy-loading with promise
  // - More segments (> 2)

  render() {
    this.clean();
    const center = this.map.getCenter();
    const centerLat = center.lat();
    const centerLng = center.lng();
    const currentHour = new Date().toLocaleTimeString('en-GB', { timeZone: 'America/New_York' }).match(/(\d{2}):\d{2}:\d{2}/)[1];
    const currentDay = new Date().toLocaleString('en-GB', { timeZone: 'America/New_York', weekday: 'short' });
    const currentTime = { hour: parseInt(currentHour), day: currentDay };
    // const currentTime = { hour: parseInt('09'), day: 'Sun' };
    // const placeTypes = new Set(); //TODO: temp

    // console.log('currentTime', currentTime)

    this.parkingSchedule.forEach((place) => {
      const placeCoords = place?.type === 'Feature' && place.geometry.coordinates.map(([lng, lat]) => ({ lng, lat }));
      const placeSchedule = place?.type === 'Feature' && place.schedule;

      if (Math.abs(placeCoords[0].lng - centerLng) <= 0.003 && Math.abs(placeCoords[0].lat - centerLat) <= 0.003 /*&& place.id === '2005547'*/) {
        const { type: placeType, segmentName: placeSegmentName } = this.getPlaceType({ place, currentTime });
        // placeTypes.add(placeType); //TODO: temp

        // if (place.id === '2085528') {
        //   console.log(placeType, placeSegmentName)
        // }

        switch (placeType) {
          case 'Free':
            if (placeSegmentName === 'Free') {
              this.draw({ markerType: 'Free', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            break;
          case 'FreeNoStanding':
            if (placeSegmentName === 'NoStanding') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'Free' || placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'NoStanding' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'StreetCleaning':
            if (placeSegmentName === 'StreetCleaning') {
              this.draw({ markerType: 'StreetCleaning', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'StreetCleaning' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'FreeStreetCleaning':
            if (placeSegmentName === 'StreetCleaning') {
              this.draw({ markerType: 'StreetCleaning', placeCoords, placeSchedule, id: place.id });
            }
            if (placeSegmentName === 'Free' || placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'StreetCleaning' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule, id: place.id });
            }
            if (placeSegmentName === 'No-negative') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule, id: place.id });
            }
            break;
          case 'TowAway':
            if (placeSegmentName === 'TowAway') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'TowAway' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'FreeTowAway':
            if (placeSegmentName === 'TowAway') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'Free' || placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'TowAway' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'Filtered':
            if (placeSegmentName === 'Filtered') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'Filtered' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'TimeLimit':
            if (placeSegmentName === 'TimeLimit') {
              this.draw({ markerType: 'TimeLimit', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'TimeLimit' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
          case 'FilteredTimeLimit':
            if (placeSegmentName === 'Filtered') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'TimeLimit') {
              this.draw({ markerType: 'TimeLimit', placeCoords, placeSchedule });
            }
            break;
          case 'NoParking':
            if (placeSegmentName === 'NoParking') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'NoParking' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            if (placeSegmentName === 'No-negative') {
              this.draw({ markerType: 'NoParkingAnytime', placeCoords, placeSchedule });
            }
            break;
          case 'NoStanding':
            if (placeSegmentName === 'NoStanding') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'NoStanding' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'FreeNoParking':
            if (placeSegmentName === 'NoParking') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'Free' || placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'NoParking' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'MeterHour':
            if (placeSegmentName === 'MeterHour') {
              const durationHours = this.getDuration(place);
              this.draw({ markerType: 'MeterHour', placeCoords, durationHours, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'MeterHour' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'MeterHourNoParking':
            if (placeSegmentName === 'MeterHour') {
              const durationHours = this.getDuration(place);
              this.draw({ markerType: 'MeterHour', placeCoords, durationHours, placeSchedule });
            }
            if (placeSegmentName === 'NoParking') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'MeterHour' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'MeterHourMeterHour': //TODO: recheck with 2 meterHours
            if (placeSegmentName === 'MeterHour') {
              const durationHours = this.getDuration(place);
              this.draw({ markerType: 'MeterHour', placeCoords, durationHours, placeSchedule });
            }
            if (placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'MeterHour' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
          case 'FreeNoStopping':
            if (placeSegmentName === 'NoStopping') {
              this.draw({ markerType: 'NoParking', placeCoords, placeSchedule });
            }
            if (placeSegmentName === 'Free' || placeSegmentName === 'No') {
              const { leftHours, leftDays } = this.countLeftHours({ currentTime, place, to: 'NoStopping' });
              this.draw({ markerType: 'Free', placeCoords, leftHours, leftDays, placeSchedule });
            }
            break;
        }
      }
    });
    // console.log(placeTypes.size);
    // console.log(placeTypes.values())
  };
};

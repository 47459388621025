import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import withWidthPicker from '../../../../hoc/withWidthPicker/withWidthPicker';
import Logo from '../../../../assets/img/icons/logo.svg';
import LoginIcon from '../../../../assets/img/icons/login.svg';

const Header = () => {
  const location = useLocation();
  return (
    <header className="header">
      <div className="container-xl">
        <div className="header-burger">
          <span />
          <span />
          <span />
        </div>
        <a className="header-logo" href={`/`}>
          <img src={Logo} alt="logo" />
        </a>
        <a className="header-logo_mobile" href={`/?map`}>
          <img src={Logo} alt="logo" />
        </a>
        <nav className="header-menu">
          <ul className="header-menu_list">
            <li className={location?.pathname === '/' ? 'active' : ''}>
              <a href={`/?map`}>
                Find parking
              </a>
            </li>
            <li className={/\/how-it-works/.test(location?.pathname) ? 'active' : ''}>
              <a href={`/how-it-works`}>
                How it works
              </a>
            </li>
            <li className={location?.pathname === '/download' ? 'active' : ''}>
              <a href={`/download`}>
                Download app
              </a>
            </li>
            <li className={location?.pathname === '/rent-out-my-driveway' ? 'active' : ''}>
              <a href={`/rent-out-my-driveway`}>
                List your space
              </a>
            </li>
            {/* <li className={location?.pathname === '/parking-pass' ? 'active' : ''}>
              <a href={`/parking-pass`}>
                Parking pass
              </a>
            </li> */}
            {/* <li className={location?.pathname === '/business' ? 'active' : ''}>
              <a href='/business'>
                Manage business
              </a>
            </li> */}
            <li className={location?.pathname === '/login' ? 'active' : ''}>
              <a href={`/login`}>
                Login
              </a>
            </li>
          </ul>
        </nav>
        <a className="header-login_mobile" href={`/login`}>
          <img src={LoginIcon} alt="logo" />
        </a>
        <div className="header-mob_bgr" />
      </div>
    </header>
  )
};

export default withWidthPicker(Header);

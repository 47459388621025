import React, {useState} from 'react';
import {ErrorMessage, Form, Formik} from "formik";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import * as Yup from "yup";
import 'antd/lib/notification/style/index.css';
import {notification} from "antd";
import {functions} from "../../utils/firebase";
import DotAnimation from '../../components/_common/UI/DotAnimation/DotAnimation';

const saveWaitList = functions.httpsCallable('addPhoneToWaiList');

const schema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+1 ([0-9]{3}) ([0-9]{3}) ([0-9]{4})/,
      'Not a valid phone number'
    )
    .required("Phone number is required")
});

const WaitListForm = () => {
  const [loading, setLoading] = useState(false);
  const onSubmit = (values, { resetForm}) => {
    setLoading(true);
    return saveWaitList(values)
      .then(() => {
        notification.success({
          message: 'Wait list',
          description: 'Data successfully sent',
        });
        resetForm();
        return true;
      })
      .catch((err) => {
        notification.error({
          message: 'Wait list',
          description: 'Error sending data',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        phone: "+1"
      }}
      validationSchema={schema}>
      {({values, handleChange, errors, touched}) => (
        <Form className="download-form google-form" id="google-form">
          <label className={classnames('download-input ', errors.phone && touched.phone && "error")}>
            <NumberFormat
              format="+# ### ### ####"
              type="tel"
              name="phone"
              className={classnames('download-input_point ', errors.phone && touched.phone && "error")}
              id="google-tel"
              placeholder="+1"
              value={values.phone}
              onChange={handleChange}
            />
            <ErrorMessage
              name="phone"
              render={msg => <div className="input-error">{msg}</div>}
            />
          </label>
          <button className="button two" id="google-btn" type="submit">
            {loading ? <DotAnimation /> : 'Notify me'}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default WaitListForm;


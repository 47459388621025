const getDuration = (place) => {
  const ONE_HOUR = 60;
  const AVG_WALK_KM_PER_HOUR = 5;
  try {
    if (window.localStorage.getItem('location')) {
      const originPlace = JSON.parse(window.localStorage.getItem('location'))
      const distance = getDistance(
        parseFloat(originPlace.lat),
        parseFloat(originPlace.lng),
        parseFloat(place.lat),
        parseFloat(place.lon)
      );
      const duration = distance * ONE_HOUR / AVG_WALK_KM_PER_HOUR;
      return duration.toFixed(0)
    }
  } catch (e) {
    console.log(e.message);
  }
  return 0;
};

const getDurationByGeocode = async (place) => {
  return new Promise(async (resolve) => {
    try {
      if (window.localStorage.getItem('location')) {
        const originPlace = JSON.parse(window.localStorage.getItem('location'))
        const service = new window.google.maps.DistanceMatrixService();
        const origin = new window.google.maps.LatLng(parseFloat(originPlace.lat), parseFloat(originPlace.lng));
        const destination = new window.google.maps.LatLng(parseFloat(place.lat), parseFloat(place.lon));
        const destinationAddress = await getAddressByLatLng(place);
        const request = {
          origins: [origin, originPlace.address],
          destinations: [destinationAddress, destination],
          travelMode: window.google.maps.TravelMode.WALKING,
          unitSystem: window.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        };
        service.getDistanceMatrix(request).then((response) => {
          resolve(response.rows[0].elements[0].duration.text);
        }).catch((e) => {
          resolve(`0 min`)
        });
      }
    } catch (e) {
      console.log(e.message);
      return resolve(`0 min`);
    }
  });
};

const getDistance = (lat1, lon1, lat2, lon2, unit = "K") => {
  const radlat1 = Math.PI * lat1 / 180;
  const radlat2 = Math.PI * lat2 / 180;
  const theta = lon1 - lon2;
  const radtheta = Math.PI * theta / 180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === "K") {
    dist = dist * 1.609344;
  }
  if (unit === "N") {
    dist = dist * 0.8684;
  }
  return dist;
};


const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const getAddressByLatLng = async (place) => {
  return new Promise(async (resolve) => {
    try {
      const service = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(parseFloat(place.lat), parseFloat(place.lon));
      const results = await service.geocode({'location': latLng});
      console.log(results, place.lat, place.lon, place.address);
      if (results.length) {
        const place = results[0];
        return resolve(place.formatted_address);
      }
    } catch (e) {
      console.log(e);
    }
    return resolve(null);
  });
};


export {
  getDuration,
  getAddressByLatLng,
  getDistance
}

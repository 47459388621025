import React from 'react';
import FirstMarketplace from '../../../assets/img/images/space/marketplace/1.svg';
import ThirdMarketplace from '../../../assets/img/images/space/marketplace/3.svg';
import MarketplaceIcon1 from '../../../assets/img/images/private-parking/marketplace/1.svg';

const Marketplace = () => (
  <section className="marketplace special-padding">
    <div className="container">
      <div className="marketplace-wrap">
        <h2 className="title-xl bg-mob">Turn Unused Parking Hours into Cash</h2>
        <ul className="marketplace-list">
          <li>
            <img src={FirstMarketplace} alt="img"/>
            <h3 className="title-md">Free & Fast<br /> Sign Up</h3>
          </li>
          <li>
            <img src={MarketplaceIcon1} alt="img"/>
            <h3 className="title-md">No Pesky<br /> Paperwork</h3>
          </li>
          <li>
            <img src={ThirdMarketplace} alt="img"/>
            <h3 className="title-md">Payouts<br /> Every Week</h3>
          </li>
        </ul>
        <h4 className="low-width-mobile title-xl">Earn Without Losing Parking Space</h4>
      </div>
    </div>
  </section>
);

export default Marketplace;


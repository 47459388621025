import React, {useState} from 'react';
import AutoComplete from "../_common/UI/Map/AutoComplete";
import DotAnimation from "../_common/UI/DotAnimation/DotAnimation";

const Form = ({mapInstance, mapApi, address, setAddress, changeLocation, types, setTypes}) => {
  const [loading, setLoading] = useState(false);
  const setCurrentLocation = () => {
    if ('geolocation' in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition((position, error) => {
        console.error('getCurrentPosition', error);
        if (error) {
          window.localStorage.setItem('currentLocation', JSON.stringify([
            position.coords.latitude, position.coords.longitude
          ]));
          changeLocation({
            center: [position.coords.latitude, position.coords.longitude],
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            zoom: 16,
            isDefault: false,
            address: ''
          });
          setAddress('');
        }
        setLoading(false);
      });
    }
  };

  const setParkingTypes = (type, checked) => {
    let prevState = [...types];
    if (checked) {
      prevState.push(type);
    } else {
      prevState = prevState.filter(item => item !== type);
    }
    setTypes(prevState);
  }
  return (
    <div className="map-top">
      <div className="map-form" id="map-form">
        <AutoComplete
          map={mapInstance}
          mapApi={mapApi}
          address={address || ''}
          setAddress={setAddress}
          changeLocation={(data) => {
            window.localStorage.setItem('currentLocation', JSON.stringify(data.center));
            changeLocation(data);
          }}
        />
        <div className="map-choice">
          <label className="map-checkbox">
            <input
              className="map-checkbox_point"
              type="checkbox"
              name="map-street"
              id="map-parking"
              checked={types.includes('street')}
              onChange={({target}) => setParkingTypes('street', target.checked)}
            />
            <span className="map-checkbox_text">
              <span className="map-checkbox_dot"/>Street parking
            </span>
          </label>
          <label className="map-checkbox">
            <input
              className="map-checkbox_point"
              type="checkbox"
              name="map-private"
              id="map-private"
              checked={types.includes('private')}
              onChange={({target}) => setParkingTypes('private', target.checked)}
            />
            <span className="map-checkbox_text">
                  <span className="map-checkbox_dot"/>
                  Private parking
                </span>
          </label>
          <label className="map-checkbox">
            <input
              className="map-checkbox_point"
              type="checkbox"
              name="map-garages"
              id="map-garages"
              checked={types.includes('garage')}
              onChange={({target}) => setParkingTypes('garage', target.checked)}
            />
            <span className="map-checkbox_text">
                  <span className="map-checkbox_dot"/>
                  Garage
                </span>
          </label>
          <label className="map-checkbox">
            <input
              className="map-checkbox_point"
              type="checkbox"
              name="map-charges"
              id="map-charges"
              checked={types.includes('charges')}
              onChange={({target}) => setParkingTypes('charges', target.checked)}
            />
            <span className="map-checkbox_text">
                  <span className="map-checkbox_dot"/>
                  EV Chargers
                </span>
          </label>
        </div>
      </div>
      <button className="button map-button" onClick={setCurrentLocation}>
        {loading ? <DotAnimation /> : 'Find parking near me'}
      </button>
    </div>
  );
}

export default Form;


import React from 'react';
import PropTypes from 'prop-types';

const Charge = ({onClick, view, place, onHover}) => {
  return (
    <div
      style={{opacity: Boolean(view) ? 1 : 0}}
      className="point-charge marker-icon"
      onClick={() => onClick(place)}
      onMouseOver={e => {
        onHover(place);
        e.preventDefault();
      }}
      onMouseOut={e => {
        onHover(null);
        e.preventDefault();
      }}
    >
      <span>{Math.round(place.price)}</span>
    </div>
  );
}

Charge.defaultProps = {
  onClick: null,
};

Charge.propTypes = {
  onClick: PropTypes.func,
  view: PropTypes.bool.isRequired,
};

export default Charge;

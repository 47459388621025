import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../../components/_common/Layout';
import SearchBox from '../../../components/_common/SearchBox';

import PhoneIcon from '../../../assets/img/icons/phone.svg';
import Car2Icon from '../../../assets/img/icons/car-2.svg';
import CarIcon from '../../../assets/img/icons/car.svg';
import ParkIcon from '../../../assets/img/icons/parking.svg';
import HandsIcon from '../../../assets/img/icons/hands.svg';
import FreeIcon from '../../../assets/img/icons/free.svg';
import AppIcon from '../../../assets/img/icons/app.svg';

import MarketplaceIcon1 from '../../../assets/img/images/street-parking/marketplace/1.svg';
import MarketplaceIcon2 from '../../../assets/img/images/street-parking/marketplace/2.svg';
import MarketplaceIcon3 from '../../../assets/img/images/street-parking/marketplace/3.svg';

import AcceptIcon1 from '../../../assets/img/images/street-parking/accept/1/1.png';
import AcceptIcon2 from '../../../assets/img/images/street-parking/accept/1/2.png';
import AcceptIcon3 from '../../../assets/img/images/street-parking/accept/1/3.png';
import AcceptIcon4 from '../../../assets/img/images/street-parking/accept/2/1.png';
import AcceptIcon5 from '../../../assets/img/images/street-parking/accept/2/2.png';
import AcceptIcon6 from '../../../assets/img/images/street-parking/accept/3/1.png';
import AcceptIcon7 from '../../../assets/img/images/street-parking/accept/3/2.png';

import AcceptIcon8 from '../../../assets/img/images/street-parking/accept/4/1.png';
import AcceptIcon9 from '../../../assets/img/images/street-parking/accept/4/2.png';

import AcceptIcon10 from '../../../assets/img/images/street-parking/accept/5/1.png';
import AcceptIcon11 from '../../../assets/img/images/street-parking/accept/5/2.png';

import Faq from '../../../components/_common/Faq';
import FirstMission from "../../../components/_common/Mission/First";
import DownloadForm from "../../../components/Download/DownloadForm";
import AIcon from "../../../assets/img/icons/a.svg";

const faqList = [
  [
    'What is ParkEagles?',
    'ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out.',
  ],
  [
    'How does ParkEagles (street parking) work?',
    'We are creating a large community of drivers that will be the platform\'s engine where people are actively helping to make street parking more visible and accessible. Share the location of the vacated street parking space with the app users (3 minutes waiting time) and get within 24 hours the same benefit wherever you are going / where necessary.',
  ],
  [
    'How much is cost?',
    'It\'s free and created for people who want to make parking faster and easier.',
  ],
  [
    'What cities is ParkEagles (street parking) in?',
    'Right now we are filling the base of street parking in New York.<br/>Boston, Washington D.C, Philadelphia, Chicago, Los Angeles, San Francisco, Baltimore, Atlanta, Portland, Seattle will be next',
  ],
  [
    'How do I find a spot on street parking?',
    '1. Use Navigation or Parking<br/>a) Navigation. Choose the location where you are going. Get offers with street parking near arrival address 10 minutes before.<br/>b) Parking. Slide to the right button Slide to search for Street Parking<br/>2. Look at the offers currently available<br/>3. Accept<br/>4. Using our navigation, arrive at the parking lot<br/>5. Park<br/>6. Share back when you leave',
  ],
  [
    'How do I share a spot on street parking?',
    'Use Navigation or Share<br/>1. Navigation<br/>a) Click on the Saved Location button (Person icon).<br/>b) Tap the Hold to Share the Street Parking button.<br/>c) Wait for a 3-minute distribution.<br/>d) If someone accepts your offer, wait for the driver to arrive and hand over your seat.<br/>2. Share<br/>a) Tap Select Location<br/>b) Select the location of the spot you are leaving<br/>c) Slide left and press the Share button<br/>d) Wait for a 3-minute distribution.<br/>e) If someone has accepted your offer, wait for the driver to arrive and hand over your seat.',
  ],
  [
    'What is Live Street Parking Map?',
    'This is our test project trying to solve the problem of finding a free space in the street parking with the help of assistants (on scooters, bicycles).',
  ],
  [
    'How does Live Street Parking Map work?',
    'Assistants run along a given route in certain city districts and mark online free places reflected on the application/site map. Users see location markers for parking spaces (green and red) and a mark for moving the assistant himself. A green blotch means that the place was marked up to 2 minutes ago, and accordingly, it is more relevant, and the red mark was marked more than 2 minutes ago (less likely to be applicable).',
  ],
  [
    'Is Live Street Parking Map free?',
    'At the moment, we do not charge any payment for this service. Our main task is to understand the demand and assess the relevance of this function.',
  ],
  [
    'How do I get the Live Street Parking Map launched in my neighborhood?',
    'Leave your request to our email office@parkeagles.com, and as soon as 100 applicants gather, we will launch a project in your area. Share this opportunity with your friends, relatives, or neighbors to grow the community, and the functionality works on the greatest collected accurate information.',
  ],
  [
    'Why don\'t I see any offers with Street parking?',
    'ParkEagles does not show fake data. It is only showing data collected in real-time. We ask each user to be more active, invite their own friends and family can be part of the community, thereby enabling ParkEagles to accumulate more of the information it collects.',
  ],
  [
    'How to be notified of a vacated parking lot near me?',
    'ParkEagles analyzes the last location of users and sends push notifications when there is any activity in this era. We also recommend adding / saving locations. Home / Work to be notified when activity is in the designated areas.',
  ],
  [
    'Is my personal data protected?',
    'Rest assured that your data is safe with us. We take privacy and security very seriously at ParkEagles. That’s why we ensure that your information is anonymized and securely stored. We also never sell any personal data. Feel free to look at our Terms of Use and Privacy Policy.',
  ]
];

const missionText = 'We are here to create a culture of the parking process in big cities. Our users are ready to share what they have when they don\'t need it. Join us now and get a parking assistant for every day.';

const StreetParkingPage = () => {
  React.useLayoutEffect(() => window.executeHome(), []);
  return (
    <Layout>
      <MetaTags>
        <title>Find Free Street Parking on ParkEagles - [Parking Map + ASP]</title>
        <meta id="meta-description" name="description" content="[Real-Time Parking Map] in your city/neighborhood. Our community of drivers shares free parking by marking the location on the map. Join us now and get a parking assistant for every day." />
        <link rel="canonical" href="https://parkeagles.com/how-it-works/street" />
      </MetaTags>
      <section className="home one">
        <div className="section-bgr" />
        <div className="section-down" />
        <div className="container">
          <div className="home-wrap">
            <div className="home-text">
              <h1 className="title-lg">Street parking</h1>
              <p className="descr-xl">Join us<br /> Receive instant parking offers<br /> Share when you leave it</p>
              <div className="home-text_wrap">
                <SearchBox placeholder={''} />
              </div>
            </div>
            <div className="home-img">
              <img src={PhoneIcon} alt="phone" />
              <img src={Car2Icon} alt="car" />
              <img src={ParkIcon} alt="parking" />
              <img src={HandsIcon} alt="hands" />
              <img src={FreeIcon} alt="free" />
              <img src={CarIcon} alt="car" />
            </div>
          </div>
        </div>
      </section>

      <div className="marketplace-mob">
        <div className="container search-box">
          <SearchBox placeholder={''} />
        </div>
      </div>

      <section className="marketplace">
        <div className="container">
          <div className="marketplace-wrap">
            <h2 className="title-xl">Start Your Parking Search With a Free</h2>
            <ul className="marketplace-list">
              <li>
                <img src={MarketplaceIcon1} alt="No Contract" />
                <h3 className="title-md">Large Community</h3>
              </li>
              <li>
                <img src={MarketplaceIcon2} alt="Save Money" />
                <h3 className="title-md">Real-Time Parking Map</h3>
              </li>
              <li>
                <img src={MarketplaceIcon3} alt="Parking Near" />
                <h3 className="title-md">Smart Navigation</h3>
              </li>
            </ul>
            <h4 className="title-xl">#1 Parking App</h4>
          </div>
        </div>
      </section>

      <section className="accept">
      <h2 className="title-xl text-center youtube-link">
        <a href='https://www.youtube.com/watch?v=Z4hWFXTauGE' target='_blank'>How It Works</a>
      </h2>
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">1. Look & Accept</h2>
              <p className="descr-lg">Get a free public parking space from our users</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Near</li>
                <li>Around</li>
                <li>There</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Near</span>
                  <img src={AcceptIcon1} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>Around</span>
                  <img src={AcceptIcon2} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>There</span>
                  <img src={AcceptIcon3} alt="Near" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept two">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">2. Go & Park</h2>
              <p className="descr-lg">Get the exact location of the parking space</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Navigation</li>
                <li>Contact</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Navigation</span>
                  <img src={AcceptIcon4} alt="Navigation" />
                </div>
                <div className="accept-box">
                  <span>Contact</span>
                  <img src={AcceptIcon5} alt="Like" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">3. New & Share</h2>
              <p className="descr-lg">Let us remind you where you parked and when you need to re-park</p>
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Saved</li>
                <li>Find</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Saved</span>
                  <img src={AcceptIcon6} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>New</span>
                  <img src={AcceptIcon7} alt="Near" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="accept two">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">4. Wait just 3 min</h2>
              <p className="descr-lg" />
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Share new</li>
                <li>Share saved</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Share new</span>
                  <img src={AcceptIcon8} alt="Share new" />
                </div>
                <div className="accept-box">
                  <span>Share saved</span>
                  <img src={AcceptIcon9} alt="Share saved" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="accept">
        <div className="container">
          <div className="accept-wrap">
            <div className="accept-text">
              <h2 className="title-xl">5. Track & Activate</h2>
              <p className="descr-lg" />
            </div>
            <div className="accept-image">
              <ul className="accept-tabs">
                <li>Way</li>
                <li>24 hours</li>
              </ul>
              <div className="accept-boxs">
                <div className="accept-box">
                  <span>Way</span>
                  <img src={AcceptIcon10} alt="Near" />
                </div>
                <div className="accept-box">
                  <span>24 hours</span>
                  <img src={AcceptIcon11} alt="Near" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Faq list={faqList} />
      <FirstMission garage priv text={missionText} />
    </Layout>
  );
}

export default StreetParkingPage;


import React from 'react';
import Google from '../../../assets/img/icons/google.svg';
import App from '../../../assets/img/icons/app.svg';

const SecondMission = () => (
  <section className="mission">
    <div className="mission-center">
      <a href='https://parkeagles.com/dashboard' className="button">
        List your space
      </a>
    </div>
    <div className="mission-top">
      <div className="container">
        <h2 className="title-lg">Our Mission</h2>
        <p className="descr-md">ParkEagles gives private parking owners an online tool to earn passive income. With our help, a parking space that is not in use becomes available to drivers for a short time. We want to create a reliable and convenient platform where each owner/holder will receive the maximum reward from participation.</p>
      </div>
    </div>
    <div className="mission-bottom">
      <div className="container">
        <ul className="mission-buttons">
          <li>
            <a rel="noopener" aria-label="google">
              <img src={Google} alt="google" />
            </a>
          </li>
          <li>
            <h2>
              Download<br />
              #1 Parking App<br />
              New York City
            </h2>
          </li>
          <li>
            <a href="https://apps.apple.com/us/app/parkeagles-1-nyc-parking-app/id1511592331" target="_blank"
              rel="noopener" aria-label="app">
              <img src={App} alt="app" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default SecondMission;


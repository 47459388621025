import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from "moment";
import { useLocation } from 'react-router';
import withWidthPicker from '../../../hoc/withWidthPicker/withWidthPicker';
import Instagram from '../../../assets/img/icons/instagram.svg';
import Twitter from '../../../assets/img/icons/twitter.svg';
import Facebook from '../../../assets/img/icons/facebook.svg';


const Footer = () => {
  const location = useLocation();
  return (<footer className="footer">
    <div className="container-xl">
      <nav className="footer-menu">
        <ul className="footer-menu_list">
          <li className={location?.pathname === '/privacy' ? 'active' : ''}>
            <a href={`/privacy`}>
              Privacy policy
            </a>
          </li>
          <li className={location?.pathname === '/terms' ? 'active' : ''}>
            <a href={`/terms`}>
              Terms of use
            </a>
          </li>
          <li className={location?.pathname === '/cookie' ? 'active' : ''}>
            <a href={`/cookie`}>
              Cookie policy
            </a>
          </li>
          <li className={location?.pathname === '/disclaimer' ? 'active' : ''}>
            <a href={`/disclaimer`}>
              Disclaimer policy
            </a>
          </li>
          <li>
            <a href={`/contact`}>
              Contact
            </a>
          </li>
        </ul>
      </nav>
      <ul className="footer-socials">
        <li>
          <a href="https://www.instagram.com/parkeagles" target="_blank" rel="noopener" aria-label="instagram">
            <img src={Instagram} alt="instagram" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/ParkEagles" target="_blank" rel="noopener" aria-label="twitter">
            <img src={Twitter} alt="twitter" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/parkeagles" target="_blank" rel="noopener" aria-label="facebook">
            <img src={Facebook} alt="facebook" />
          </a>
        </li>
      </ul>
      <span className="footer-rights">{moment().year()}©ParkEagles. All rights reserved.</span>
    </div>
  </footer>)
};

export default withWidthPicker(withRouter(Footer));

import React from 'react';
import { Link } from 'react-router-dom';
import Google from '../../../assets/img/icons/google.svg';
import App from '../../../assets/img/icons/app.svg';
import PrivateIcon from '../../../assets/img/images/private-parking/accept/mission/private.svg';
import GaragesIcon from '../../../assets/img/images/private-parking/accept/mission/garages.svg';
import StreetIcon from '../../../assets/img/images/private-parking/accept/mission/street.svg';

const FirstMission = ({ garage, priv, street, text }) => (
  <section className="mission">
    <div className="mission-top first">
      <div className="container">
        <h2 className="title-lg">Our Mission</h2>
        <p className="descr-md">{text}</p>
      </div>
      <div className="mission-links-conatiner">
        {garage &&
          <a href={`/how-it-works/garage`}>
            <img src={GaragesIcon} alt="garages" />
          </a>
        }
        {priv &&
          <a href={`/how-it-works/private`}>
            <img src={PrivateIcon} alt="private-parking" />
          </a>
        }
        {street &&
          <a href={`/how-it-works/street`}>
            <img src={StreetIcon} alt="street" />
          </a>
        }
      </div>
    </div>
    <div className="mission-bottom">
      <div className="container">
        <ul className="mission-buttons">
          <li>
            <a rel="noopener" aria-label="google">
              <img src={Google} alt="google" />
            </a>
          </li>
          <li>
            <h2>
              Download<br />
              #1 Parking App<br />
              New York City
            </h2>
          </li>
          <li>
            <a href="https://apps.apple.com/us/app/parkeagles-1-nyc-parking-app/id1511592331" target="_blank"
              rel="noopener" aria-label="app">
              <img src={App} alt="app" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default FirstMission;


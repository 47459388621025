import React from 'react';

import withWidthPicker from '../../hoc/withWidthPicker/withWidthPicker';

import Arrow3 from '../../assets/img/icons/arrow-3.svg';
import Hydrant from '../../assets/img/icons/hydrant.svg';
import Street from '../../assets/img/icons/street.svg';

const Home = () => {
  return (
    <section className="preloader">
      <div className="preloader-bgr preloader-one" id="lottie1"/>
      <div className="preloader-bgr preloader-two" id="lottie2"/>
      <div className="preloader-bgr preloader-three" id="lottie3"/>
      <div className="preloader-hydrant">
        <h3><b>No Parking</b><b>Hydrant</b></h3>
        <div className="preloader-hydrant_arrow">
          <img src={Arrow3} alt="arrow"/>
        </div>
        <img src={Hydrant} alt="Hydrant"/>
      </div>
      <div className="preloader-street">
        <h3><b>On / Off</b><b>Street Parking</b></h3>
        <div className="preloader-street_arrow">
          <img src={Arrow3} alt="arrow"/>
        </div>
        <img src={Street} alt="street"/>
      </div>
    </section>
  );
};

export default withWidthPicker(Home);


import React, {useState} from 'react';
import {Formik, Form, ErrorMessage} from 'formik';
import 'antd/lib/notification/style/index.css';
import {notification} from "antd";
import classnames from "classnames";
import * as Yup from "yup";
import NumberFormat from 'react-number-format';
import {functions} from "../../utils/firebase";
import DotAnimation from "../_common/UI/DotAnimation/DotAnimation";

const sendContactEmail = functions.httpsCallable('landingSendEmail');

const schema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+1 ([0-9]{3}) ([0-9]{3}) ([0-9]{4})/,
      'Not a valid phone number'
    )
    .required("Phone number is required"),
  email: Yup.string()
    .email('Invalid email')
    .required("Required"),
  name: Yup.string()
    .required("Name is required"),
  message: Yup.string()
    .required("Message is required"),
});

const ContactForm = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (values, { resetForm }) => {
    const updatedValues = {
      ...values,
      name: values.name.replace(/\s/g, ""),
      message: values.message.replace(/\s/g, ""),
    };
    setLoading(true);
    return sendContactEmail({
      subject: 'Contact form',
      email: updatedValues.email,
      message: `
        <p>Name: ${updatedValues.name}</p>
        <p>Email: ${updatedValues.email}</p>
        <p>Phone: ${updatedValues.phone}</p>
        <p>Message: ${updatedValues.message}</p>
      `
    })
      .then(() => {
        notification.success({
          message: 'Contact form',
          description: 'Data successfully sent',
        });
        resetForm();
        return true;
      })
      .catch(() => {
        notification.error({
          message: 'Contact form',
          description: 'Error sending form',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        phone: "+1",
        email: "",
        name: "",
        message: ""
      }}
      validationSchema={schema}>
      {({values, handleChange, errors, touched}) => (
        <Form className="contact-form" id="contact-form">
          <label className={classnames('input', errors.name && touched.name && "error")}>
            <span className="input-label">Name *</span>
            <input
              className={classnames('input-point', errors.name && touched.name && "error")}
              type="text"
              name="name"
              id="contact-name"
              placeholder="Input your name"
              value={values.name}
              onChange={handleChange}
            />
            <ErrorMessage
              name="name"
              render={msg => <div className="input-error">{msg}</div>}
            />
          </label>
          <label className={classnames('input', errors.email && touched.email && "error")}>
            <span className="input-label">Email address *</span>
            <input
              className={classnames('input-point ', errors.email && touched.email && "error")}
              type="email"
              name="email"
              id="contact-email"
              placeholder="Input your email"
              value={values.email}
              onChange={handleChange}
            />
            <ErrorMessage
              name="email"
              render={msg => <div className="input-error error">{msg}</div>}
            />
          </label>
          <label className={classnames('input', errors.phone && touched.phone && "error")}>
            <span className="input-label">Phone number *</span>
            <NumberFormat
              format="+# ### ### ####"
              type="tel"
              name="phone"
              className={classnames('input-point ', errors.phone && touched.phone && "error")}
              id="contact-phone"
              placeholder="Input your phone number"
              value={values.phone}
              onChange={handleChange}
            />
            <ErrorMessage
              name="phone"
              render={msg => <div className="input-error error">{msg}</div>}
            />
          </label>
          <label className={classnames('input', errors.message && touched.message && "error")}>
            <span className="input-label">Message *</span>
            <textarea
              className={classnames('input-point ', errors.message && touched.message && "error")}
              name="message"
              id="contact-message"
              placeholder="Text message"
              value={values.message}
              onChange={handleChange}
            />
            <ErrorMessage
              name="message"
              render={msg => <div className="input-error error">{msg}</div>}
            />
          </label>
          <button className="button contact-btn" id="contact-btn" type="submit">
            {loading ? <DotAnimation /> : 'Send message'}
          </button>
        </Form>
      )}
    </Formik>
  )
}


export default ContactForm;

import React, {useState} from 'react';
import {notification} from "antd";
import {functions} from "../../utils/firebase";
import * as Yup from "yup";
import {ErrorMessage, Form, Formik} from "formik";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import DotAnimation from "../_common/UI/DotAnimation/DotAnimation";

const sendSms = functions.httpsCallable('landingSendSMS');

const schema = Yup.object().shape({
  phone: Yup.string()
    .matches(
      /\+1 ([0-9]{3}) ([0-9]{3}) ([0-9]{4})/,
      'Not a valid phone number'
    )
    .required("Phone number is required")
});

const DownloadForm = ({name = 'download', children = null}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (values, {resetForm}) => {
    setLoading(true);
    return sendSms({
      phone: values.phone,
      text: 'https://apps.apple.com/us/app/parkeagles-1-nyc-parking-app/id1511592331'
    })
      .then(() => {
        notification.success({
          message: 'Download now',
          description: 'Sms sent successfully',
        });
        resetForm();
        return true;
      })
      .catch((err) => {
        notification.error({
          message: 'Download now',
          description: 'Error sending sms',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        phone: "+1"
      }}
      validationSchema={schema}>
      {({values, handleChange, errors, touched}) => (
        <Form className={`${name}-form google-form`} id={`${name}-form`}>
          {children}
          <label className={classnames( `${name}-input `, errors.phone && touched.phone && "error")}>
            <NumberFormat
              format="+# ### ### ####"
              type="tel"
              name="phone"
              className={classnames(`${name}-input_point `, errors.phone && touched.phone && "error")}
              id="google-tel"
              placeholder="+1"
              value={values.phone}
              onChange={handleChange}
            />
            <ErrorMessage
              name="phone"
              render={msg => <div className="input-error">{msg}</div>}
            />
          </label>
          <button className="button two" id="google-btn" type="submit">
            {loading ? <DotAnimation /> : 'Send link'}
          </button>
        </Form>
      )}
    </Formik>
  );
}

export default DownloadForm;


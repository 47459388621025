// Marker.js
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/*
<div className="container">
  <div className="point-one"> > 2<br /> min</div>
  <div className="point-two"> > 2<br /> min</div>
</div>
*/

const SMALL_TIME = 120;
const FULL_TIME = 300;

const Street = ({view, place}) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const date = place.date;
    let interval = null;
    // let tmp = 250;
    interval = setInterval(() => {
      const secondDiff = moment().unix() - moment(date).unix();
      // const secondDiff = tmp;
      // tmp++;
      if (secondDiff <= FULL_TIME) {
        if (secondDiff <= FULL_TIME) {
          setTimer(secondDiff);
        } else {
          setTimer(0);
          clearInterval(interval);
        }
      } else {
        setTimer(0);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      if (interval) {
        setTimer(0);
        clearInterval(interval);
      }
    };
  }, [place.date]);

  if (!timer) return null;

  if (timer > SMALL_TIME) {
    return (
      <div
        style={{opacity: Boolean(view) ? 1 : 0}}
        className="point-two marker-icon"
        onClick={() => {
        }}
      >
        {">"} 2 <br/> min
      </div>
    );
  }

  return (
    <div
      style={{opacity: Boolean(view) ? 1 : 0}}
      className="point-one marker-icon"
      onClick={() => {
      }}
    >
      {"<"} 2 <br/> min
    </div>
  );
};

Street.defaultProps = {
  onClick: null,
};

Street.propTypes = {
  view: PropTypes.bool.isRequired,
};

export default Street;

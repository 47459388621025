// Autocomplete.js
import React, {Component} from 'react';
import DotAnimation from "../DotAnimation/DotAnimation";

class AutoComplete extends Component {
  constructor() {
    super();
    this.state = {
      isEmptyAddress: false,
      address: '',
      loading: false
    }
  }

  componentDidMount({map, mapApi} = this.props) {
    const options = {
      // restrict your search to a specific type of result
      types: ['address'],
      // restrict your search to a specific country, or an array of countries
      // componentRestrictions: { country: ['gb', 'us'] },
    };
    this.autoComplete = new mapApi.places.Autocomplete(
      this.searchInput,
      options,
    );
    this.autoComplete.addListener('place_changed', () => {
      const place = this.autoComplete.getPlace();
      this.setState({
        address: place.formatted_address
      });
    });
    this.autoComplete.bindTo('bounds', map);
    this.searchInput.value = this.props.address;
    this.setState({
      address: this.props.address
    });
  }

  componentWillUnmount({mapApi} = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps.address, 'prevProps');
    if (prevProps.address !== this.props.address) {
      // this.searchInput.value = this.props.address;
      this.setState({
        address: this.props.address
      })
    }
  }

  onPlaceChanged = () => {
    const {mapApi} = this.props;
    const {address} = this.state;

    const place = this.autoComplete.getPlace();
    if (!place?.geometry) {
      if (this.searchInput.value) return;
      this.props.changeLocation('');
      this.setState({
        isEmptyAddress: true,
        address: ''
      });
      return;
    }
    this.setState({loading: true});

    const geocoder = new mapApi.Geocoder();
    geocoder.geocode({'address': address}, (results, status) => {
      if (status === 'OK' && results.length) {
        const place = results[0];
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.props.changeLocation({
          center: [lat, lng],
          lat,
          lng,
          zoom: 15,
          isDefault: false,
          address: place.formatted_address
        });
        this.props.setAddress(address);
        this.searchInput.blur();
        this.setState({
          isEmptyAddress: false,
        });
      } else {
        this.props.changeLocation('');
        this.setState({
          isEmptyAddress: true,
        });
      }
      this.setState({loading: false});
    });
  };

  render() {
    const {isEmptyAddress, address, loading} = this.state;
    return (
      <div className="map-search">
        <label className="map-input">
          <input
            id="map-search"
            className="map-input_point"
            ref={(ref) => {
              this.searchInput = ref;
            }}
            type="text"
            name="search"
            style={{borderColor: isEmptyAddress ? 'red' : 'transparent'}}
            placeholder="Input city or address"
            value={address}
            onChange={({target: {value}}) => {
              this.setState({
                address: value
              })
            }}
          />
        </label>
        <button
          className="button two map-btn" id="map-btn"
          onClick={this.onPlaceChanged.bind(this)}
        >
          {loading ? <DotAnimation/> : 'Search'}
        </button>
      </div>
    );
  }
}

export default AutoComplete;

import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../components/_common/Layout';

const TermsPage = () => (
  <Layout>
    <MetaTags>
      <meta name="robots" content="noindex, nofollow" />
    </MetaTags>
    <section className="section-one single">
      <div className="container">
        <h1 className="section-title">Terms of Use</h1>
        <div className="single-wrap">
          <p>We at ParkEagles (together with our affiliates, referred to as "ParkEagles," "us," "our," or "we") would like to thank you for your interest in our website, mobile applications, and related Services (collectively referred to as our "Platform"). The terms and conditions outlined in this document, along with any other documents incorporated by reference (collectively referred to as the "Terms"), and our privacy policy govern your use of our Platform. Please read these Terms and our privacy policy thoroughly before accessing or using our Platform.</p>
          <p>When you visit our App ParEagles, and use our services, you trust us with your personal information. We
            take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights you have in relation to it. We hope
            you take some time to read through it carefully, as it is important. If there are any terms in this
            privacy policy that you do not agree with, please discontinue use of our Sites and our services.</p>
          <p>By accessing and using our Platform, you acknowledge and agree to the following:</p>
          <p>You have read, understood, and agree to be bound by these Terms and our privacy policy.<br></br>
            You are of legal age to enter into a binding contract and have the right, authority, and capacity to enter into and abide by these Terms and our privacy policy.
            These Terms and our privacy policy legally bind you.<br></br>
            If you do not agree to these Terms and our privacy policy, do not use our Platform. If you are using our Platform on behalf of an entity, you agree to these Terms and our privacy policy on behalf of that entity.
          </p>
          <p>
            We reserve the right to modify these Terms and our privacy policy at any time. Such modifications will be effective upon posting to our Platform or providing an email notice to you. If you continue to use our Platform and/or Services after the revised Terms have been posted or notified, it will be deemed that you have accepted the modifications. Please review these Terms periodically for changes and pay attention to the "Last Updated" date displayed at the bottom of this page.
          </p>
          <h3>2. DISCLAIMER OF ATTORNEY-CLIENT RELATIONSHIP</h3>
          <p>
            ParkEagles (i) is not a law firm; (ii) does not perform legal services that an attorney performs; and (iii) is not authorized to engage in the practice of law, including without limitation providing any type of opinion or recommendation about potential legal rights, defenses, or strategies. You acknowledge, understand, and agree that ParkEagles is not in any way your lawyer, legal representative, or counsel. No attorney-client relationship is created between ParkEagles and any of its users, including you, as a result of using our platform and/or services. All information you provide us, while protected as outlined in our privacy policy, is not protected by attorney-client privilege, nor is it considered attorney work product.
            Any attorney-client relationship formed between you and any service provider (as defined) through our services, including but not limited to traffic services, is between you and the service provider and not between ParkEagles and you. ParkEagles is designed to facilitate communication between its users and service providers but does not endorse any specific attorney or service provider. It cannot be held responsible for the quality or accuracy of any legal services provided by any attorney or service provider. If you choose to initiate a relationship with an attorney or service provider through our platform, you do so at your discretion. ParkEagles does not assume and will not have any liability or responsibility in connection with this. Any attorney or service provider retained by you through our platform will only be obligated to provide legal representation in connection with the parking or traffic violation ticket(s) you have specifically provided (whether through our platform or not).
          </p>
          <h3>3. USE OF PARKING SERVICES BY PARKEAGLES</h3>
          <p>
            Territorial Coverage. Our platform's parking ticket processing services are not restricted to just New York City tickets and may be expanded to other cities and jurisdictions. ParkEagles reserves the right to modify these terms accordingly.
            Submitting Parking Tickets. It is advisable to submit parking tickets through our platform as soon as possible after receiving them, as different cities have different deadlines for responding to parking violations. If you don't submit the tickets at least 7 business days before the deadline, you will be solely responsible for any additional costs, including late fees, that may arise from the delay.
          </p>
          <p>
            Service Providers. ParkEagles provide parking services through its partners, affiliates, contractors, representatives, and agents (collectively called "Service Providers"). Using these services, you authorize our Service Providers to represent you in front of the relevant bureau and any other relevant government or municipal authority regarding any parking tickets you submitted through our platform or received through our Auto-Dispute Program.
          </p>
          <p>
            Standstill Agreement. After submitting a parking ticket through our platform, you agree to:
          </p>
          <p>
            Do not take any action that will interfere, disrupt, or circumvent our Service Providers' efforts on your behalf.<br></br>
            Not take any action related to the ticket without our written consent until ParkEagles informs you of its decision to either not dispute the ticket, in which case you are responsible for disputing it or paying the fine, or of the bureau's decision regarding the ticket.<br></br>
            Allow our Service Providers to take any necessary or appropriate action in disputing the ticket.
            Auto Dispute Program; Ticket Fetcher. You may choose to participate in our Auto-Dispute Program at the time of creating your account or later by giving us authorization to periodically search the bureau databases for parking tickets linked to your license plate and dispute any new tickets found. You can opt out of this program anytime through your account settings. You acknowledge that it is your responsibility to turn off the Auto-Dispute Program if you do not want to use it. ParkEagles will not dispute any future tickets linked to your license plate without your prior approval.
          </p>
          <p>
            ParkEagles handles many parking tickets through our platform, and it is not feasible to respond to individual requests regarding the status of any specific case. Therefore, ParkEagles will not answer questions about specific parking tickets, hearings, or decisions. The Ticket Fetcher feature allows you to check the status of your pending tickets or to verify if a new ticket has been issued to your license plate. It is recommended to periodically check the relevant bureau databases for new tickets and follow up with the bureau directly for the status of pending tickets.
          </p>
          <p>
            Please note that the Auto-Dispute Program and Ticket Fetcher features are for your convenience. ParkEagles cannot guarantee that all tickets linked to your license plate will be accurately and fully obtained or displayed through our platform. If you participate in the program or use the Ticket Fetcher service, ParkEagles reserves the right to notify you of new tickets through any means, including email, SMS, or phone. PARKEAGLES MAKES NO WARRANTIES REGARDING THE ACCURACY OF INFORMATION OBTAINED THROUGH OUR PLATFORM OR RELATED FEATURES OR PROGRAMS.
          </p>
          <p>
            Cancellation Policy. Once a parking ticket is submitted through our platform, it cannot be canceled or reversed without our written consent. If you submit a ticket by mistake, please contact our customer support within 6 hours of submission. Failure to do so will be considered as your acceptance of the submission.
          </p>
          <h3>4. TRAFFIC SERVICES</h3>
          <p>
            Submitting Your Traffic Violation Tickets. It is recommended that you submit your traffic violation ticket through our Platform as soon as possible after receipt, as most municipalities have specific deadlines for responding to such violations. Submitting a ticket with an expired or upcoming hearing date increases the risk of license suspension. If you do not submit your traffic violations promptly, you will be solely responsible for any additional costs, such as late fees, that may result from the delay. ParkEagles reserves the right to reject any traffic ticket submitted if we determine that it cannot be processed on time or if the available attorneys on our Platform decline to take on your case. In such cases, you should not rely on ParkEagles or its participating attorneys to handle your ticket or represent you in connection with it.
          </p>
          <h3>5. FEES</h3>
          <p>
            Payment Method. It is your responsibility to keep your payment method up to date and to ensure it is valid and accepted. All fees charged to you are exclusive of any taxes and duties. By using our platform, you authorize ParkEagles to automatically charge your payment method for any fees incurred while using our services.<br></br>
            Services Fees. ParkEagles will only collect payment for the Parking Ticket Dispute Services if we successfully resolve your parking ticket in your favor. In such a case, you authorize ParkEagles to charge your payment method an amount equal to 30% of the difference between the original fine on your parking ticket (including any late fees and penalties) and the final amount after the Parking Ticket Services have been provided.<br></br>
            For example, if the original fine on your parking ticket was $100:<br></br>
            If ParkEagles cannot successfully dispute the ticket: No charge.<br></br>
            If ParkEagles successfully disputes the ticket, it is fully dismissed: a $30 charge.<br></br>
            Payment Processing. If ParkEagles is unsuccessful in fully dismissing your parking ticket, you can pay it through our platform using our "Pay" feature. A processing fee, which varies by territory, will be charged to your payment method and disclosed before payment. Payments made through the "Pay" or “AutoPay” feature are non-refundable, as we immediately pay the bureau on your behalf. All fees are subject to change at ParkEagles' discretion.
          </p>
          <p>
            Traffic Services and Platform Usage Fees. All registered users of our Services are required to pay a one-time Platform usage fee for each traffic ticket, which may be adjusted at any time by ParkEagles. ParkEagles will process the Platform usage fee after you submit your ticket to the platform and only once we receive confirmation of your engagement with an attorney. You will not be charged if you choose not to engage an attorney through the platform. This fee helps cover a portion of ParkEagles' operating costs and expenses.
          </p>
          <p>
            Attorney Fees. Once you choose the attorney you wish to work with from the available options on our platform, you will enter into a direct agreement with the attorney. The legal fees you pay through the platform for your legal representation will be passed on to the attorney in full. You understand and agree that once you appoint an attorney through the platform, you will be charged the applicable legal fees and any other payments, as instructed by the attorney or yourself, through the payment method on your account. The attorney will retain 100% of the fees you pay through the platform, and ParkEagles does not receive any portion of these fees.
          </p>
          <p>
            Bond Payment. If you miss your court date or fail to appear, you may be required to post a bond (usually $40 per ticket) to reschedule your trial. In this case, you authorize ParkEagles to automatically charge the bond amount on the attorney's instructions. If you are found not guilty, you will receive the bond money back after the trial. The bond will apply to any fines owed if you are found guilty.
          </p>
          <p>
            Intermediary Role. ParkEagles is merely an intermediary party in transactions with the attorney and is not liable for paying the attorney or any third party, except through the platform on your behalf. If payment cannot be processed through the platform, you will still be responsible for paying the attorney the applicable legal fees.
          </p>
          <p>
            Payment Failure. If ParkEagles is unable to process a payment fee or if a payment is canceled, a service fee of Five Dollars ($5.00) per failed payment attempt will be charged, by applicable laws. Late or delinquent payments will also incur an interest rate of one and a half percent (1.5%) per month from the due date or the maximum amount allowed by law. The customer will be solely responsible for any "insufficient fund" charges or penalties imposed by their bank. In the event of an unsuccessful payment, ParkEagles will immediately discontinue its services unless otherwise notified. The customer will be responsible for paying both the ticket fees and the service fees directly. Additionally, delinquent accounts and/or chargebacks will be charged fees and charges related to the collection of these accounts, including collection fees, convenience fees, and third-party charges. ParkEagles reserves the right to charge interest at an annualized rate of 18% or the highest rate permitted by law. All communications regarding delinquent accounts will be made by email or phone, as provided by the customer to ParkEagles. These communications may be made by ParkEagles or any third party acting on its behalf, including a collection agency. The remedies outlined in this section do not detract from any other rights or remedies available to ParkEagles under the law or these terms.
          </p>
          <p>
            Non-Refundable; Money-Back Guarantee. ParkEagles does not guarantee the success of its services. Therefore, all fees paid to ParkEagles are non-refundable, unless the customer qualifies for and has paid for ParkEagles' ticket case protection money-back guarantee program. For more information about this program, please visit Case Protection Terms. Upon inputting a ticket into the ParkEagles platform, the customer will be notified if their ticket qualifies for the money-back guarantee program. If no notification is received, the ticket does not qualify, the customer is in a state that prohibits such programs, or ParkEagles, at its discretion, has determined the customer does not qualify. Suppose a notification is received, and the customer chooses to pay the premium offered by ParkEagles. In that case, they will receive a full refund of all payments made (including the attorney retainer fee) only if no reduction in points and/or fine is received on the ticket. Participation in the money-back guarantee program for one ticket does not affect any other tickets inputted into ParkEagles. Each ticket is evaluated individually, and the premium must be paid per ticket. Unless an official message from the ParkEagles platform confirms that the money-back guarantee covers a specific ticket after payment of the premium, the ticket will not be covered. ParkEagles' money-back guarantee is unavailable for all tickets and not in jurisdictions that prohibit such refunds.
          </p>
          <p>
            ParkEagles Installment Payment Program. ParkEagles may offer its installment payment program (the “Payment Program”) to eligible users in certain markets and for specific categories of traffic tickets. If your ticket qualifies for our Payment Program, you will be prompted to enroll during the checkout process. ParkEagles reserves the right to modify its eligibility and qualification requirements and to withhold or refuse participation in the Payment Program at any time and for any reason, subject to applicable laws and regulations. Enrolling in the Payment Program is optional and will not impact access to the Platform or the ability to retain a licensed attorney featured on our platform. ParkEagles may use a third party to manage the Payment Program and may share your enrollment, payment, and ticket information with them. To participate, you must make a down payment, which includes an amount approved by ParkEagles and any vendor processing fees, and pay the balance (along with any other fees and surcharges imposed by ParkEagles) through monthly installment payments over a mutually agreed-upon period. If you fail to make the down payment, your enrollment in the Payment Program will be void. ParkEagles may also charge a finance fee and a default fee, which will be disclosed at the time of enrollment. Suppose you fail to make a monthly installment payment promptly. In that case, your participation in the Payment Program may be terminated, and you will be required to pay all remaining fees within 24 hours or risk losing access to the Platform (unless waived by ParkEagles). No changes to the Payment Program terms will be binding unless approved by a ParkEagles representative via email.
          </p>
          <p>
            Suspension and Collections. If the balance is not paid according to these Terms, your account will be suspended and may be sent to a third-party collection agency. If that happens, you will be responsible for paying all collection fees assessed by the agency. ParkEagles or a designated third-party collection agency may contact you by telephone, email, or text message (message and data rates may apply), and use pre-recorded messages or automatic dialing devices if necessary. Suspension or termination of your account may result in termination or suspension of legal services provided by the attorney you engaged through the Platform, at the sole discretion of the attorney and/or ParkEagles. You agree that you will not contest the termination or suspension of legal services due to your failure to pay the balance promptly.
          </p>
          <h3>
            6. PROHIBITED USES
          </h3>
          <p>
            The ParkEagles Platform and Services can only be used if you are at least 18 years old and meet the age of consent in your jurisdiction. Additionally, you must use our platform and services only for lawful purposes and follow all applicable laws and regulations.
          </p>
          <p>
            You agree not to:
          </p>
          <p>
            Disable, damage, interfere with, or violate the security of the Platform or its servers, or restrict others from using it, using malware, or any other means.<br></br>
            Modify, damage, or delete any material on the Platform or Services.<br></br>
            Reverse engineer, disassemble or attempt to access the source code of any software used on the Platform.<br></br>
            Rent, sell, license, or otherwise make the Platform available to any third party.<br></br>
            Remove, circumvent, or otherwise work around any security features of the Platform.<br></br>
            Threaten, harass, stalk, spam, abuse, or violate the legal rights of other users.<br></br>
            Engage in any conduct that restricts others from using the Platform or exposes ParkEagles or its users to liability.<br></br>

          </p>
          <h3>7. TERMINATION OF AGREEMENT</h3>
          <p>
            Account Closure. ParkEagles reserves the right to terminate your license to use our platform, and block your access to it, at any time and without prior notice, subject to the “Pending Tickets” section below. You may terminate your license anytime by following our account closure procedures on the app or by notifying us in writing. However, termination is not permitted and will not apply to any tickets submitted through our platform for which services are still being provided (“Ongoing Services”).<br></br>
            Pending Tickets. Regardless of the termination rights of both parties, you will still be bound by these terms and any payment obligations in connection with the Ongoing Services.
          </p>
          <p>
            Termination for Breach. ParkEagles may terminate your account and license if you violate any of these terms, fail to maintain a valid payment method, or default on any payment obligation for services, including failure to make payment within 24 hours under a payment program.
          </p>
          <p>
            Effect of Termination. Upon termination, your rights under these terms will end, and you must stop using our platform and cannot submit new tickets. You will still be responsible for all fees, charges, and obligations incurred through the termination date, including for Ongoing Services. Terminating your use of our platform and services will not limit ParkEagles' rights and remedies at law or in equity, which are reserved.
          </p>
          <h3>
            8. THIRD-PARTY MATERIALS.
          </h3>
          <p>
            The ParkEagles platform may display, include or provide access to third-party content (including information and materials) or links to third-party websites or services through advertising (collectively referred to as “Third-Party Materials”). You acknowledge and agree that ParkEagles is not responsible for the accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect of Third-Party Materials. ParkEagles does not assume and will not have any liability or responsibility for Third-Party Materials. Access to and use of Third-Party Materials is solely at your own risk and subject to the terms and conditions of the relevant third party.
          </p>
          <h3>
            9. COMMUNICATION WITH CUSTOMERS.
          </h3>
          <p>
            During your use of the ParkEagles platform, you authorize ParkEagles to contact you through email, SMS, chat, or direct phone if ParkEagles requires your cooperation regarding any parking or traffic ticket you have uploaded to the platform or for any other reason related to your account or promotions. By registering an account and using our services, you consent to ParkEagles contacting you through any of the above methods. ParkEagles will not contact you via SMS to sell you different products or services unless you affirmatively consent.
          </p>
          <h3>
            10. DISCLAIMERS.
          </h3>
          <p>
            ParkEagles and its service providers do not make any warranty, guarantee, or representation regarding the completeness, security, quality, suitability, accuracy, or availability of the ParkEagles platform or services. ParkEagles and its service providers do not represent or warrant that the use of the platform, its content, or any of the services will be error-free or uninterrupted, free of viruses or harmful components, or meet your expectations. ParkEagles does not make any representations, warranties, or guarantees regarding the integrity, competence, qualifications, standing, status, suitability, compliance with professional rules, accuracy, or legality of the advice provided by attorneys or the outcome, quality, or completion of their representation. ParkEagles hereby disclaims all warranties of any kind, express or implied, statutory or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for a particular purpose.
          </p>
          <h3>
            11. LIMITATION OF LIABILITY.
          </h3>
          <p>
            You acknowledge and agree that, to the maximum extent permitted by law, all risks arising from your access to and use of the ParkEagles platform and services remain with you. ParkEagles, its managers, officers, members, employees, representatives, agents, or service providers will not be liable for any indirect or consequential damages, including but not limited to any loss of profits or anticipated savings, emotional distress, loss of data, use, goodwill, or other monetary or intangible losses, resulting from (1) your access to or use of (or inability to access or use) the ParkEagles platform or services; (2) any content submitted to or accessed through the platform; (3) any services provided on or obtained through the platform; or (4) any communications or dealings with, or conduct of, other users, service providers, and attorneys.
          </p>
          <h3>
            12. FORCE MAJEURE
          </h3>
          <p>
            In addition to the disclaimers mentioned above, our failures to fulfill our obligations under these Terms due to reasons beyond our control, such as acts of God, government actions, war, civil unrest, natural disasters (including but not limited to fire, earthquakes, and elements), explosions, acts of terrorism, power outages, equipment failure, labor disputes, errors or failures from third party information providers, third-party software, or communication interruptions, will not be considered a violation of these Terms and will be fully excused.
          </p>
          <h3>
            13. INDEMNIFICATION
          </h3>
          <p>
            You agree to defend, indemnify, and hold harmless ParkEagles, our affiliates, independent contractors, service providers, and consultants, as well as their respective officers, employees, agents, affiliates, successors, and assigns, against any claims, damages, costs, liabilities, and expenses (including reasonable attorney's fees) arising from or related to (i) your use or inability to use our platform and/or services, (ii) your breach or violation of these terms, any applicable law, or the rights of a third party, and/or (iii) your gross negligence, fraud, or intentional misconduct.
          </p>
          <h3>
            14. PROMOTIONS; REFERRAL PROGRAMS
          </h3>
          <p>
            ParkEagles may offer promotions with different features to its users or potential users at its discretion. If ParkEagles determines that a promotion was redeemed in error, fraudulently, illegally, or in violation of the terms, it reserves the right to withhold or deduct the associated credits or benefits. ParkEagles may also offer incentives for referring new users to the platform through the Referral Program. Your participation in the Referral Program is subject to these Terms and any additional terms and conditions that ParkEagles may establish and enforce from time to time.
          </p>
          <h3>
            15. INTELLECTUAL PROPERTY
          </h3>
          <p>
            Our platform and its features, functionality, and content, as well as the information and materials on it, are the exclusive property of ParkEagles, its licensors, and/or third-party material providers, and are protected by copyright or other intellectual property laws. Using our platform and/or services does not give you ownership of any intellectual property rights in our platform or the content you access. Suppose you choose to communicate suggestions for improvement to us in connection with our platform or services. In that case, ParkEagles will own all rights, titles, and interests in and to these suggestions and may use them without restriction.
          </p>
          <h3>
            16. COPYRIGHT POLICY
          </h3>
          <p>
            This is a policy regarding copyright infringement on the website. If users believe that any information or material violates their rights, they may submit a notification according to the Digital Millennium Copyright Act (DMCA). The notification should include the following information: identification of the copyrighted work that is allegedly infringed, a physical or electronic signature of the person authorized to act on behalf of the copyright owner, information to contact the person making the notification, a statement that they have a good faith belief that the use of the material is not authorized, and a statement that the information in the notification is accurate. If the content provided by the user was removed and is not infringing, they may send a counter-notice. The counter-notice should include the user's name and contact information, identification of the removed content, a statement that they believe the removal was a mistake, and a statement that they consent to the jurisdiction of a federal court in New York and will accept service of process. The original complaining party will be informed if the removed content is restored. The restored content may be replaced within ten to fourteen business days after receipt of the counter-notice at the website's discretion.
          </p>
          <h3>
            17. UPDATES
          </h3>
          <p>
            ParkEagles may periodically release updates for its mobile application, including upgrades, bug fixes, patches, error corrections, and new features. It is important for you to promptly download and install these updates to ensure the proper functioning of the ParkEagles platform. Please note that updates may also modify or delete certain features and functionality.
          </p>
          <h3>
            18. USER-PROVIDED CONTENT
          </h3>
          <p>
            Suppose the ParkEagles platform or services allow you to contribute content, such as uploading data, posting messages, sharing files, or communicating with others. In that case, you agree to not post, upload, transmit, distribute, or otherwise make available any data, text, images, files, links, software, or communications that:<br></br>
            i. violate any applicable laws, as determined by ParkEagles in its sole discretion;<br></br>
            ii. restrict or inhibit any other user from using and enjoying the ParkEagles platform or services;<br></br>
            iii. are threatening, abusive, harassing, defamatory, obscene, offensive, harmful, obnoxious, pornographic, profane, or indecent;<br></br>
            iv. contain viruses, worms, or other harmful components that could interrupt, destroy, interfere with, or limit the functionality of any computer software, hardware, or communications network;<br></br>
            v. violate or infringe on the rights of others, including, but not limited to, rights under copyright, trademark, or patent law, rights of privacy or publicity, rights under defamation law, or rights under any securities or other applicable law;<br></br>
            vi. sell or promote any product or service;<br></br>
            vii. promote, solicit, or participate in any multi-level marketing or pyramid scheme;<br></br>
            viii. post or transmit any unsolicited advertising or promotional materials (spam);<br></br>
            ix. provide a false identity or claim a relationship with any business, organization, or person for which you are not authorized or legally permitted to claim such a relationship.
          </p>
          <h3>
            19. PRIVACY
          </h3>
          <p>
            All personal information included in electronic communications with the ParkEagles platform or services will be governed by the Privacy Policy. ParkEagles, as the owner of the platform and services, reserves the right to use or copy all other information in these communications, including any ideas, inventions, concepts, techniques, or know-how, for any purpose, including disclosure to third parties and the development, manufacturing, and/or marketing of goods or services. The sender of such communications will be responsible for their content and accuracy.
          </p>
          <p>
            To provide you with the ParkEagles platform and services, and by the Privacy Policy, which is incorporated into these terms, the platform and services may need to collect, use, and disclose your personal information. ParkEagles reserves the right to modify the Privacy Policy at any time, and these modifications will become effective upon posting to the platform. ParkEagles will not be held responsible for any loss, damage, or expenses resulting from changes to the Privacy Policy. Every time you use the platform, you consent to the collection, use, and disclosure of your personal information by the Privacy Policy as it then reads. You understand that third parties may collect, use, or disclose your personal information while using the platform and services and that such collection, use, or disclosure is subject to their privacy policies.
          </p>
          <h3>
            20. ASSIGNMENT AND THIRD-PARTY BENEFICIARIES
          </h3>
          <p>
            You may not assign or delegate these Terms or Privacy Policy in whole or part, and any attempt to do so will be invalid. ParkEagles reserves the right to assign all or a portion of its rights and obligations under these Terms and Privacy Policy at its sole discretion. The provisions of these Terms are intended for the benefit of ParkEagles, its successors, and assigns, and will inure to their benefit. No person or entity, except for the parties, their successors, and assigns, will have any legal or equitable right to enforce any provision of these Terms.
          </p>
          <h3>
            21. ARBITRATION AGREEMENT
          </h3>
          <p>
            You and ParkEagles agree to resolve any disputes or claims arising from or related to these Terms, the use of ParkEagles' Platform, the actions of ParkEagles, or any services offered through the Platform through final and binding arbitration, instead of in court. The Federal Arbitration Act and, if not inconsistent with the FAA, the laws of the State of New York, govern this Agreement to Arbitrate.
          </p>
          <p>
            YOU AND PARKEAGLES AGREE TO ONLY BRING CLAIMS AGAINST EACH OTHER ON AN INDIVIDUAL BASIS AND NOT AS A CLASS MEMBER OR REPRESENTATIVE. UNLESS BOTH YOU AND PARKEAGLES AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN CLAIMS FROM MORE THAN ONE PERSON, AND MAY NOT PRESIDE OVER A CONSOLIDATED, REPRESENTATIVE, OR CLASS ACTION. THE ARBITRATOR MAY AWARD RELIEF ONLY TO THE INDIVIDUAL PARTY SEEKING IT, TO THE EXTENT NECESSARY TO ADDRESS THAT PARTY'S CLAIMS. NO RELIEF AWARDED CAN IMPACT OTHER USERS. Arbitration is more informal than court proceedings and uses a neutral arbitrator instead of a judge or jury, with limited court review of an arbitration award.
          </p>
          <p>
            The American Arbitration Association will administer the arbitration under its rules and procedures, which may be modified by this Agreement to Arbitrate. The AAA will decide which rules apply to the arbitration. AAA rules can be found at www.adr.org and www.icdr.org.
          </p>
          <p>
            To initiate arbitration, a party must send a Notice of Dispute to the other party by certified mail. The Notice to ParkEagles should be sent to ParkEagles LLC, Attn: Legal, Re: Notice of Dispute, 1239 Broadway, New York, NY 10001. ParkEagles will send a Notice to the physical address on file with your ParkEagles account. To be valid, the Notice must be personally signed and include a description of the claims being made, the relief sought, and the email address and phone number associated with your account.
          </p>
          <p>
            Suppose you and ParkEagles cannot resolve the claims in the Notice within 30 days of ParkEagles receiving it. In that case, either party may initiate arbitration proceedings by filing a form on the AAA's site at www.adr.org and mailing a copy of the form to the other party. Any settlement offer made during arbitration shall not be disclosed to the arbitrator.
          </p>
          <p>
            The arbitration hearing will be held in New York City or another mutually agreed location. If the claims or counterclaims do not exceed $25,000, the dispute may be resolved by submitting documents only, subject to the arbitrator's discretion. The arbitrator's decision will be final and binding.
          </p>
          <h3>
            22. Miscellaneous
          </h3>
          <p>
            These Terms and Conditions form the complete agreement between you and ParkEagles regarding our platform and services and replace all prior or contemporaneous understandings and agreements, whether written or oral, related to it. Your access to our platform or use of the services shall not be considered as creating a partnership, employment, agency, or joint venture relationship between ParkEagles and you. Neither party shall be considered to have waived any right by failing to or delaying the exercise of such right, nor shall any single or partial exercise of any right prevent further exercise of that or any other right. In case of conflict between these Terms and any applicable purchase agreement or other terms, these shall govern. Suppose any provision of these Terms is illegal or unenforceable under applicable law. In that case, it will be amended to achieve as closely as possible the original intent of the term, and all other provisions of these Terms will continue in full force and effect. These Terms will be binding on and will inure to the benefit of, both parties and their respective successors and assigns.
          </p>
          <h3>
            23. Notices; Contact Us
          </h3>
          <p>
            Any notices or other communications permitted or required under these Terms will be in writing and delivered by ParkEagles to you (i) by email (to the address that you maintain with your account), or (ii) by posting on our platform. You are responsible for maintaining your email inbox and agree to frequently check your inbox. We are not responsible for any missed communication or any consequences caused by such missed communication. All notices will be considered effective one (1) business day after successful transmission (or posting). If you have any questions about these Terms or need to contact us for any reason, please email
            us at <a href="mailto:office@parkeagles.com">office@parkeagles.com</a>
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default TermsPage;


import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../components/_common/Layout';
import { Link } from 'react-router-dom';
import Icon1 from '../../assets/img/images/parking/1.svg';
import Icon2 from '../../assets/img/images/parking/1_1.svg';
import Icon3 from '../../assets/img/images/parking/2.svg';
import Icon4 from '../../assets/img/images/parking/2_1.svg';
import Icon5 from '../../assets/img/images/parking/3.svg';
import Icon6 from '../../assets/img/images/parking/3_1.svg';

import CarIcon from '../../assets/img/icons/car-3.svg';

const ParkingPage = () => {
  React.useLayoutEffect(() => window.executeHome(), []);
  return (
    <Layout>
      <MetaTags>
        <title>Find The Best Parking To You With [ParkEagles]</title>
        <meta id="meta-description" name="description" content="ParkEagles is a peer-to-peer marketplace that connects drivers looking for parking with residents and business owners who have spaces to share or rent out." />
        <link rel="canonical" href="https://parkeagles.com/how-it-works" />
      </MetaTags>
      <section className="parking">
        <div className="parking-bgr" id="lottie4" />
        <ul className="parking-boxs">
          <li>
            <a href={`/how-it-works/private`}>
              <span className="parking-boxs_img">
                <img src={Icon1} alt="img" />
                <img src={Icon2} alt="img" />
              </span>
              <h3>Private Parking</h3>
            </a>
          </li>
          <li>
            <a href={`/how-it-works/garage`}>
              <span className="parking-boxs_img">
                <img src={Icon3} alt="img" />
                <img src={Icon4} alt="img" />
              </span>
              <h3>Garages</h3>
            </a>
          </li>
          <li>
            <a href={`/how-it-works/street`}>
              <span className="parking-boxs_img">
                <img src={Icon5} alt="img" />
                <img src={Icon6} alt="img" />
              </span>
              <h3>Street parking</h3>
            </a>
          </li>
        </ul>
        <div className="swiper-container parking-slider">
          <div className="swiper-wrapper">
            <a className="swiper-slide parking-slide one" href={`/how-it-works/private`}>
              <span className="parking-slide_bgr" />
              <div className="container">
                <div className="parking-slide_wrap">
                  <img src={Icon1} alt="img" />
                  <h3>Private Parking</h3>
                  <img src={CarIcon} alt="car" />
                  <h4>Unique offers</h4>
                </div>
              </div>
            </a>
            <a className="swiper-slide parking-slide two" href={`/how-it-works/garage`}>
              <span className="parking-slide_bgr" />
              <div className="container">
                <div className="parking-slide_wrap">
                  <img src={Icon3} alt="img" />
                  <h3>Garages</h3>
                  <img src={CarIcon} alt="car" />
                  <h4>Discounted rates</h4>
                </div>
              </div>
            </a>
            <a className="swiper-slide parking-slide three" href={`/how-it-works/street`}>
              <span className="parking-slide_bgr" />
              <div className="container">
                <div className="parking-slide_wrap">
                  <img src={Icon5} alt="img" />
                  <h3>Street parking</h3>
                  <img src={CarIcon} alt="car" />
                  <h4>Open public spots</h4>
                </div>
              </div>
            </a>
          </div>
          <div className="parking-navigation">
            <svg className="parking-arrow parking-arrow_prev" width="20" height="30" viewBox="0 0 20 30" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.02666 11.2685L11.7236 1.45023C13.6121 -0.464326 16.6909 -0.464326 18.5794 1.45023C19.4958 2.3756 20 3.61024 20 4.92097C20 6.2317 19.4958 7.46635 18.5794 8.39172L12.3103 14.7392L18.5794 21.0867C19.4958 22.0145 20 23.2467 20 24.5574C20 25.8682 19.4958 27.1004 18.5794 28.0282C17.663 28.956 16.4461 29.4665 15.1515 29.4665C13.857 29.4665 12.64 28.956 11.7236 28.0282L2.02666 18.2099C1.1103 17.2846 0.60606 16.0499 0.60606 14.7392C0.60606 13.4285 1.1103 12.1938 2.02666 11.2685Z"
                fill="#4DA1FF" />
            </svg>
            <div className="parking-pagination" />
            <svg className="parking-arrow parking-arrow_next" width="20" height="30" viewBox="0 0 20 30" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.9733 11.2685L8.27636 1.45023C6.38788 -0.464326 3.30909 -0.464326 1.42061 1.45023C0.504243 2.3756 0 3.61024 0 4.92097C0 6.2317 0.504243 7.46635 1.42061 8.39172L7.6897 14.7392L1.42061 21.0867C0.504243 22.0145 0 23.2467 0 24.5574C0 25.8682 0.504243 27.1004 1.42061 28.0282C2.33697 28.956 3.55394 29.4665 4.84848 29.4665C6.14303 29.4665 7.36 28.956 8.27636 28.0282L17.9733 18.2099C18.8897 17.2846 19.3939 16.0499 19.3939 14.7392C19.3939 13.4285 18.8897 12.1938 17.9733 11.2685Z"
                fill="#4DA1FF" />
            </svg>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ParkingPage;


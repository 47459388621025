import React from 'react';
import MetaTags from 'react-meta-tags';
import Layout from '../../components/_common/Layout';

const PrivacyPage = () => (
  <Layout>
    <MetaTags>
      <meta name="robots" content="noindex, nofollow" />
    </MetaTags>
    <section className="section-one single">
      <div className="container">
        <h1 className="section-title">Privacy policy</h1>
        <div className="single-wrap">
          <h3>Last updated April 27, 2020</h3>
          <p>Thank you for choosing to be part of ParkEagles (“<b>Company</b>”, “<b>we</b>”, “<b>us</b>”, or
            “<b>our</b>”). We are committed to protecting your personal information and your right to privacy. If you
            have any questions or concerns about our policy, or our practices with regards to your personal
            information, please contact us at <a href="mailto:support@parkeagles.com">support@parkeagles.com</a></p>
          <p>When you visit our App ParEagles, and use our services, you trust us with your personal information. We
            take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights you have in relation to it. We hope
            you take some time to read through it carefully, as it is important. If there are any terms in this
            privacy policy that you do not agree with, please discontinue use of our Sites and our services.</p>
          <p>This privacy policy applies to all information collected through our App (such as ParkEagles), and/or any
            related services, sales, marketing or events (we refer to them collectively in this privacy policy as the
            "<b>Services</b>").</p>
          <p><b>Please read this privacy policy carefully as it will help you make informed decisions about sharing
            your personal information with us.</b></p>
          <h3>TABLE OF CONTENTS</h3>
          <ol>
            <li>WHAT INFORMATION DO WE COLLECT?</li>
            <li>HOW DO WE USE YOUR INFORMATION?</li>
            <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
            <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
            <li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
            <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li>DATA BREACH</li>
            <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
            <li>DO WE MAKE UPDATES TO THIS POLICY?</li>
            <li>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</li>
          </ol>
          <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
          <p><b>Personal information you disclose to us</b></p>
          <p><em><b>In Short:</b> We collect personal information that you provide to us such as name, address,
            contact information, car information, phone number, birth date, security data, and payment
            information.</em></p>
          <p><b>In addition we collect also:</b> A brand, model, year, color, plate number, state, type of plate of
            your car.</p>
          <p>We collect personal information that you voluntarily provide to us when registering at our App and
            expressing an interest in obtaining information about us or our products and services, when participating
            in activities on the Services or otherwise contacting us.</p>
          <p>The personal information that we collect depends on the context of your interactions with us and the
            Services, the choices you make and the products and features you use. The personal information we collect
            can include the following:</p>
          <p>We collect this information in our Share functions and according to the data, cars are going to be
            grouped by size, and users will index each other by it- according to the information that users will
            provide. When booking garages and private parking, owners or operators will identify users as
            customers.</p>
          <p>This information is collected for the purpose of the parking process to provide better service for the
            users and achieve the purpose of the App</p>
          <p>You will be able to use your our app once you enter a 6 digit code that will be send to your phone number
            in the form of SMS.- Only if require to open an account by visiting our app and entering the required
            information (name, surname, date of birth, phone number).</p>
          <p>In addition we may collect your credit card information, for which your personal information will be
            totally protected through Stripe as a payment gateway.</p>
          <p>All personal information that you provide to us must be true, complete and accurate, and you must notify
            us of any changes to such personal information.</p>
          <p><b>Information automatically collected</b></p>
          <p><em><b>In Short:</b> Some information — such as IP address and/or browser and device characteristics — is
            collected automatically when you visit our Services.</em></p>
          <p>We automatically collect certain information when you visit, use or navigate the Services. This
            information does not reveal your specific identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and device characteristics, operating
            system, language preferences, referring URLs, device name, country, location, information about how and
            when you use our Services and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and reporting
            purposes.</p>
          <p>Like many businesses, we also collect information through cookies and similar technologies. You can find
            out more about this in our Cookies Policy: TermlyCookiePolicy.org.</p>
          <h3>2. HOW DO WE USE YOUR INFORMATION?</h3>
          <p><em><b>In Short:</b> We process your information for purposes based on legitimate business interests, the
            fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></p>
          <p>We use personal information collected via our Services for a variety of business purposes described
            below. We process your personal information for these purposes in reliance on our legitimate business
            interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance
            with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose
            listed below.</p>
          <p>Below are some definitions that will help you understand the roles and responsibilities of
            ParkEagles </p>
          <ul>
            <li>data controller” means a person who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any personal information are, or are to be
              used.
            </li>
            <li>“data processor”, in relation to personal information, means any person (other than an employee of the
              data controller) who processes the data on behalf of the data controller.
            </li>
          </ul>
          <p>If you provide the data and the instructions, then you are the data controller and ParkEagles is the data
            processor.</p>
          <p>If we determine the purposes for which we collect and use your personal information, then we are the
            Controller.</p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li><b>To send administrative information to you.</b> We may use your personal information to send you
              product, service and new feature information and/or information about changes to our terms, conditions,
              and policies.
            </li>
            <li><b>To protect our Services.</b> We may use your information as part of our efforts to keep our
              Services safe and secure (for example, for fraud monitoring and prevention).
            </li>
            <li><b>To enable user-to-user communications.</b> We may use your information in order to enable
              user-to-user communications with each user's consent.
            </li>
            <li><b>To deliver services to the user.</b> We may use your information to provide you with the requested
              service.
            </li>
          </ul>
          <h3>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
          <p><em><b>In Short:</b> We only share information with your consent, to comply with laws, to provide you
            with services, to protect your rights, or to fulfill business obligations.</em></p>
          <p>We may process or share data based on the following legal basis:</p>
          <ul>
            <li><b>Consent:</b> We may process your data if you have given us specific consent to use your personal
              information in a specific purpose.
            </li>
            <li><b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our
              legitimate business interests.
            </li>
            <li><b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your
              personal information to fulfill the terms of our contract.
            </li>
            <li><b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in
              order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal
              process, such as in response to a court order or a subpoena (including in response to public authorities
              to meet national security or law enforcement requirements).
            </li>
            <li><b>Vital Interests:</b> We may disclose your information where we believe it is necessary to
              investigate, prevent, or take action regarding potential violations of our policies, suspected fraud,
              situations involving potential threats to the safety of any person and illegal activities, or as
              evidence in litigation in which we are involved.
            </li>
          </ul>
          <p>More specifically, we may need to process your data or share your personal information in the following
            situations:</p>
          <ul>
            <li><b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third
              party vendors, service providers, contractors or agents who perform services for us or on our behalf and
              require access to such information to do that work. Examples include: payment processing, data analysis,
              email delivery, hosting services, customer service and marketing efforts. We may allow selected third
              parties to use tracking technology on the Services, which will enable them to collect data about how you
              interact with the Services over time. This information may be used to, among other things, analyze and
              track data, determine the popularity of certain content and better understand online activity. Unless
              described in this Policy, we do not share, sell, rent or trade any of your information with third
              parties for their promotional purposes.
            </li>
            <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during
              negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
          </ul>
          <h3>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
          <p><b>In Short:</b> We may use cookies and other tracking technologies to collect and store your
            information.</p>
          <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
            information. Specific information about how we use such technologies and how you can refuse certain
            cookies is set out in our Cookie Policy: TermlyCookiePolicy.org.</p>
          <h3>5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
          <p><em><b>In Short:</b> We may transfer, store, and process your information in countries other than your
            own.</em></p>
          <p>Our servers are located in United States. If you are accessing our Services from outside United States,
            please be aware that your information may be transferred to, stored, and processed by us in our facilities
            and by those third parties with whom we may share your personal information (see "<a
              href="https://app.termly.io/dashboard/website/334706/privacy-policy#infoshare" target="blank">WILL YOUR
              INFORMATION BE SHARED WITH ANYONE?</a>" above), in United States, and other countries.</p>
          <p>If you are a resident in the European Economic Area, then these countries may not have data protection or
            other laws as comprehensive as those in your country. We will however take all necessary measures to
            protect your personal information in accordance with this privacy policy and applicable law.</p>
          <p>European Commission's Standard Contractual Clauses:</p>
          <p>Such measures implementing the European Commission's Standard Contractual Clauses for transfers of
            personal information between our group companies and between us and our third-party providers, which
            require all such recipients to protect personal information that they process from the EEA in accordance
            with European data protection laws. Our Standard Contractual Clauses can be provided upon request. We have
            implemented similar appropriate safeguards with our third party service providers and partners and further
            details can be provided upon request.</p>
          <p>EU-U.S. Privacy Shield Framework:</p>
          <p>In particular Company complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S.
            Department of Commerce regarding the collection, use, and retention of personal information transferred
            from the European Union to the United States and has certified its compliance with it. As such, Company is
            committed to subjecting all personal information received from European Union (EU) member countries, in
            reliance on the Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about
            the Privacy Shield Framework, visit the <a href="https://www.privacyshield.gov/list" target="blank">U.S.
              Department of Commerce’s Privacy Shield List.</a></p>
          <p>Company is responsible for the processing of personal information it receives, under the Privacy Shield
            Framework, and subsequently transfers to a third party acting as an agent on its behalf.</p>
          <p>With respect to personal information received or transferred pursuant to the Privacy Shield Framework,
            Company is subject to the regulatory enforcement powers of the U.S. FTC. In certain situations, we may be
            required to disclose personal information in response to lawful requests by public authorities, including
            to meet national security or law enforcement requirements.</p>
          <h3>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
          <p><em><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined
            in this privacy policy unless otherwise required by law.</em></p>
          <p>We will only keep your personal information for as long as it is necessary for the purposes set out in
            this privacy policy, unless a longer retention period is required or permitted by law (such as tax,
            accounting or other legal requirements). No purpose in this policy will require us keeping your personal
            information for longer than 1 year.</p>
          <p>When we have no ongoing legitimate business need to process your personal information, we will either
            delete or anonymize it, or, if this is not possible (for example, because your personal information has
            been stored in backup archives), then we will securely store your personal information and isolate it from
            any further processing until deletion is possible.</p>
          <p>EXERCISING ACCESS, DATA PORTABILITY, AND DELETION RIGHTS</p>
          <p>To exercise the access, data portability, and deletion rights under the CCPA described above, please
            submit a verifiable consumer request to us by:</p>
          <p>Only a California resident or a person registered with the California Secretary of State that a
            California resident has authorized to act on their behalf, may make a verifiable consumer request related
            to their personal information.</p>
          <h3>7. DO WE COLLECT INFORMATION FROM MINORS?</h3>
          <p><em><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of
            age.</em></p>
          <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
            you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent
            to such minor dependent’s use of the Services. If we learn that personal information from users less than
            18 years of age has been collected, we will deactivate the account and take reasonable measures to
            promptly delete such data from our records. If you become aware of any data we have collected from
            children under age 18, please contact us at sucessafterservice.org</p>
          <h3>8. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
          <p><em><b>In Short:</b> In some regions, such as the European Economic Area, you have rights that allow you
            greater access to and control over your personal information. You may review, change, or terminate your
            account at any time.</em></p>
          <p>In some regions (like the European Economic Area), you have certain rights under applicable data
            protection laws. These may include the right (i) to request access and obtain a copy of your personal
            information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
            information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the
            right to object to the processing of your personal information. To make such a request, please use the <a
              href="https://app.termly.io/dashboard/website/334706/privacy-policy#contact" target="_blank">contact
              details</a> provided below. We will consider and act upon any request in accordance with applicable data
            protection laws.</p>
          <p>If we are relying on your consent to process your personal information, you have the right to withdraw
            your consent at any time. Please note however that this will not affect the lawfulness of the processing
            before its withdrawal.</p>
          <p><b>Account Information</b></p>
          <p>If you would at any time like to review or change the information in your account or terminate your
            account, you can:</p>
          <ul>
            <li>Log into your account settings and update your user account.</li>
            <li>Contact us using the contact information provided.</li>
          </ul>
          <p>Upon your request to terminate your account, we will deactivate or delete your account and information
            from our active databases. However, some information may be retained in our files to prevent fraud,
            troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal
            requirements.</p>
          <p><b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose
            to remove cookies or reject cookies, this could affect certain features or services of our Services. To
            opt-out of interest-based advertising by advertisers on our Services visit <a
              href="http://www.aboutads.info/choices" target="blank">http://www.aboutads.info/choices/</a>. For
            further information, please see our Cookie Policy: TermlyCookiePolicy.org.</p>
          <p><b>Opting out of email marketing:</b> You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided
            below. You will then be removed from the marketing email list – however, we will still need to send you
            service-related emails that are necessary for the administration and use of your account. To otherwise
            opt-out, you may:</p>
          <h3>9. DATA BREACH</h3>
          <p>A privacy breach occurs when there is unauthorized access to or collection, use, disclosure or disposal
            of personal information. You will be notified about data breaches when ParkEagles believes you are likely
            to be at risk or serious harm. For example, a data breach may be likely to result in serious financial
            harm or harm to your mental or physical well-being. In the event that ParkEagles becomes aware of a
            security breach which has resulted or may result in unauthorized access, use or disclosure of personal
            information ParkEagles will promptly investigate the matter and notify the applicable Supervisory
            Authority not later than 72 hours after having become aware of it, unless the personal data breach is
            unlikely to result in a risk to the rights and freedoms of natural persons.</p>
          <h3>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
          <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
            (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your
            online browsing activities monitored and collected. No uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals
            or any other mechanism that automatically communicates your choice not to be tracked online. If a standard
            for online tracking is adopted that we must follow in the future, we will inform you about that practice
            in a revised version of this privacy policy.</p>
          <h3>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
          <p><em><b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding
            access to your personal information.</em></p>
          <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
            California residents to request and obtain from us, once a year and free of charge, information about
            categories of personal information (if any) we disclosed to third parties for direct marketing purposes
            and the names and addresses of all third parties with which we shared personal information in the
            immediately preceding calendar year. If you are a California resident and would like to make such a
            request, please submit your request in writing to us using the contact information provided below.</p>
          <p>If you are under 18 years of age, reside in California, and have a registered account with the Services,
            you have the right to request removal of unwanted data that you publicly post on the Services. To request
            removal of such data, please contact us using the contact information provided below, and include the
            email address associated with your account and a statement that you reside in California. We will make
            sure the data is not publicly displayed on the Services, but please be aware that the data may not be
            completely or comprehensively removed from our systems.</p>
          <h3>12. DO WE MAKE UPDATES TO THIS POLICY?</h3>
          <p><b>In Short:</b> Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
          <p>We may update this privacy policy from time to time. The updated version will be indicated by an updated
            “Revised” date and the updated version will be effective as soon as it is accessible. If we make material
            changes to this privacy policy, we may notify you either by prominently posting a notice of such changes
            or by directly sending you a notification. We encourage you to review this privacy policy frequently to be
            informed of how we are protecting your information.</p>
          <h3>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
          <p>Based on the laws of some countries, you may have the right to request access to the personal information
            we collect from you, change that information, or delete it in some circumstances. To request to review,
            update, or delete your personal information, please submit a request by contacting us. We will respond to
            your request within 30 days.</p>
          <p>For more information please contact us by:</p>
          <p>Email address: <a href="mailto:support@parkeagles.com">support@parkeagles.com</a></p>
          <p>Website: <a href="https://www.parkeagles.com" target="blank">parkeagles.com</a></p>
        </div>
      </div>
    </section>
  </Layout>
);

export default PrivacyPage;


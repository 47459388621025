import React from 'react';

const Faq = ({ list }) => {
  const listFirstPart = list.slice(0, 7);
  const listSecondPart = list.slice(7, 14);

  return (
    <section className="faq">
      <div className="container">
        <h2 className="title-xl comments-mob-font">Frequently Asked Questions</h2>
        <div className="faq-wrap">
          <ul className="faq-list">
            {listFirstPart.map((item, i) => (
              <li key={i}>
                <div className="faq-list_title">
                  <h3>{item[0]}</h3>
                </div>
                <div className="faq-list_text">
                  <p className="descr-sm" dangerouslySetInnerHTML={{ __html: item[1] }} />
                </div>
              </li>
            ))}
          </ul>
          <ul className="faq-list">
            {listSecondPart.map((item, i) => (
              <li key={i}>
                <div className="faq-list_title">
                  <h3>{item[0]}</h3>
                </div>
                <div className="faq-list_text">
                  <p className="descr-sm" dangerouslySetInnerHTML={{ __html: item[1] }} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
};

export default Faq;
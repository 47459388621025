import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Info from '../../assets/img/icons/info.svg';
import GarageHouseIcon from '../../assets/img/icons/garage-house.svg';
import MoneyIcon from '../../assets/img/images/space/money.png';

const MONTH_WEEKS = 4;
const YEAR_WEEKS = 48;
const CITY_PRICE = {
  bronx: 10,
  brooklyn: 10,
  manhattan: 20,
  ny: 10,
  staten: 10
}

const Header = () => {
  const [parking, setParking] = useState('bronx');
  const [nights, setNights] = useState(1);

  const [pricePerMonth, setPricePerMonth] = useState(0);
  const [pricePerYear, setPricePerYear] = useState(0);

  const calculate = () => {
    setPricePerMonth(MONTH_WEEKS * CITY_PRICE[parking] * nights);
    setPricePerYear(YEAR_WEEKS * CITY_PRICE[parking] * nights);
  };

  useEffect(() => {
    calculate();
  }, []);

  return (
    <>
      <MetaTags>
        <title>Rent Out Your Parking Space [$30/day] or [$450/month]</title>
        <meta id="meta-description" name="description" content="Rent out your driveway or garage for a short time is an excellent passive income idea. [$100 SIGN UP BONUS] Turn your unused parking hours into cash" />
        <link rel="canonical" href="https://parkeagles.com/rent-out-my-driveway" />
      </MetaTags>
      <section className="section-one space">
        <div className="section-bgr" />
        <div className="section-down" />
        <div className="space-money">
          <img src={MoneyIcon} alt="money" />
        </div>

        <div className="container">
          <div className="space-wrap">
            <div className="space-text">
              <h1 className="title-lg">
                Open Your Driveway to Hosting<br />
                And Earn Up To $350/month<br />
                Get a Guaranteed Guest<br />
                Within 24 hours
              </h1>
            </div>
            <div className="space-garge-house">
              <img src={GarageHouseIcon} alt="money" />
            </div>
            <div className="space-form" id="space-form">
              <div className="select-wrap">
                <span className="select-wrap_title">My parking in</span>
                <div className="select">
                  <select name="parking" id="space-parking" value={parking} onChange={({ target: { value } }) => setParking(value)}>
                    <option value="bronx">The Bronx, NY</option>
                    <option value="brooklyn">Brooklyn, NY</option>
                    <option value="manhattan">Manhattan, NY</option>
                    <option value="ny">Queens, NY</option>
                    <option value="staten">Staten Island, NY</option>
                  </select>
                </div>
              </div>
              <div className="select-wrap">
                <span className="select-wrap_title">Nights per week</span>
                <div className="select">
                  <select name="nights" id="space-hours" value={nights} onChange={({ target: { value } }) => setNights(value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
              </div>
              <button className="button space-btn" id="space-btn" onClick={calculate}>Calculate</button>
              <span className="space-info">
                <span className="space-info_text">You could earn</span>
                <span className="space-info_wrap">
                  <img src={Info} alt="info" />
                  <span className="space-info_descr">Based on the average hourly parking price in each area.</span>
                </span>
              </span>
              <ul className="space-list">
                <li>$ <span id="space-month">{pricePerMonth}</span>/per month</li>
                <li>$ <span id="space-year">{pricePerYear}</span>/per year</li>
              </ul>
              <a href='https://parkeagles.com/dashboard' className="button space-calc-button">
                Get started
              </a>
            </div>
            <a href='https://parkeagles.com/dashboard' className="button space-button">
              Get started
            </a>
            {/* <a href='https://calendly.com/for-individuals' target="_blank" className="button schedule-button">
            Schedule a Demo
          </a> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;


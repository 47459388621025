import React, { useEffect } from 'react';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useLocation } from 'react-router';

const LIST = [
  {
    name: 'Parking in Atlanta',
    location: 'Atlanta',
    type: 'link'
  },
  {
    name: 'Parking in Austin',
    location: 'Austin',
    type: 'link'
  },
  {
    name: 'Parking in Baltimore',
    location: 'Baltimore',
    type: 'link'
  },
  {
    name: 'Parking in Boston',
    location: 'Boston',
    type: 'link'
  },
  {
    name: 'Parking in Buffalo',
    location: 'Buffalo',
    type: 'link'
  },
  {
    name: 'Parking in Chicago',
    location: 'Chicago',
    type: 'link'
  },
  {
    name: 'Parking in Dallas',
    location: 'Dallas',
    type: 'link'
  },
  {
    name: 'Parking in Denver',
    location: 'Denver',
    type: 'link'
  },
  {
    name: 'Parking in Detroit',
    location: 'Detroit',
    type: 'link'
  },
  {
    name: 'Parking in Hoboken',
    location: 'Hoboken',
    type: 'link'
  },
  {
    name: 'Parking in Houston',
    location: 'Houston',
    type: 'link'
  },
  {
    name: 'Parking in Indianapolis',
    location: 'Indianapolis',
    type: 'link'
  },
  {
    name: 'Parking in Jersey City',
    location: 'Jersey City',
    type: 'link'
  },
  {
    name: 'Parking in Las Vegas',
    location: 'Las Vegas',
    type: 'link'
  },
  {
    name: 'Parking in Los Angeles',
    location: 'Los Angeles',
    type: 'link'
  },
  {
    name: 'Parking in Miami',
    location: 'Miami',
    type: 'link'
  },
  {
    name: 'Parking in Milwaukee',
    location: 'Milwaukee',
    type: 'link'
  },
  {
    name: 'Parking in Minneapolis',
    location: 'Minneapolis',
    type: 'link'
  },
  {
    name: 'Parking in Nashville',
    location: 'Nashville',
    type: 'link'
  },
  {
    name: 'Parking in New Orleans',
    location: 'New Orleans',
    type: 'link'
  },
  {
    name: 'Parking in New York City (NYC)',
    location: '',
    type: 'text'
  },
  {
    name: 'Parking in Brooklyn*',
    location: 'Brooklyn NY',
    type: 'link'
  },
  {
    name: 'Parking in Bronx*',
    location: 'Bronx NY',
    type: 'link'
  },
  {
    name: 'Parking in Manhattan*',
    location: 'Manhattan NY',
    type: 'link'
  },
  {
    name: 'Parking in Queens*',
    location: 'Queens NY',
    type: 'link'
  },
  {
    name: 'Parking in Staten Island*',
    location: 'Staten Island NY',
    type: 'link'
  },
  {
    name: 'Parking in Philadelphia',
    location: 'Philadelphia',
    type: 'link'
  },
  {
    name: 'Parking in Phoenix',
    location: 'Phoenix',
    type: 'link'
  },
  {
    name: 'Parking in Pittsburgh',
    location: 'Pittsburgh',
    type: 'link'
  },
  {
    name: 'Parking in San Antonio',
    location: 'San Antonio',
    type: 'link'
  },
  {
    name: 'Parking in San Diego',
    location: 'San Diego',
    type: 'link'
  },
  {
    name: 'Parking in San Francisco',
    location: 'San Francisco',
    type: 'link'
  },
  {
    name: 'Parking in San Jose',
    location: 'San Jose',
    type: 'link'
  },
  {
    name: 'Parking in Seattle',
    location: 'Seattle',
    type: 'link'
  },
  {
    name: 'Parking in Washington DC',
    location: 'Washington DC',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Atlanta',
    location: 'Atlanta',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Austin',
    location: 'Austin',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Baltimore',
    location: 'Baltimore',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Boston',
    location: 'Boston',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Buffalo',
    location: 'Buffalo',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Chicago',
    location: 'Chicago',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Dallas',
    location: 'Dallas',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Denver',
    location: 'Denver',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Detroit',
    location: 'Detroit',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Hoboken',
    location: 'Hoboken',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Houston',
    location: 'Houston',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Indianapolis',
    location: 'Indianapolis',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Jersey City',
    location: 'Jersey City',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Las Vegas',
    location: 'Las Vegas',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Los Angeles',
    location: 'Los Angeles',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Miami',
    location: 'Miami',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Milwaukee',
    location: 'Milwaukee',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Minneapolis',
    location: 'Minneapolis',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Nashville',
    location: 'Nashville',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in New Orleans',
    location: 'New Orleans',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in New York City (NYC)',
    location: 'New York City',
    type: 'text'
  },
  {
    name: 'EV Charging Stations in Brooklyn*',
    location: 'Brooklyn NY',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Bronx*',
    location: 'Bronx NY',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Manhattan*',
    location: 'Manhattan NY',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Queens*',
    location: 'Queens NY',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Staten Island*',
    location: 'Staten Island NY',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Philadelphia',
    location: 'Philadelphia',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Phoenix',
    location: 'Phoenix',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Pittsburgh',
    location: 'Pittsburgh',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in San Antonio',
    location: 'San Antonio',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in San Diego',
    location: 'San Diego',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in San Francisco',
    location: 'San Francisco',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in San Jose',
    location: 'San Jose',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Seattle',
    location: 'Seattle',
    type: 'link'
  },
  {
    name: 'EV Charging Stations in Washington DC',
    location: 'Washington DC',
    type: 'link'
  },
]

const CitiesList = ({ tab }) => {
  const {
    placePredictions,
    getPlacePredictions,
    placesService
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });
  const locationFromURL = useLocation();

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        ({ geometry, formatted_address }) => {
          const lat = geometry.location.lat();
          const lng = geometry.location.lng();
          const location = {
            address: formatted_address,
            center: [lat, lng],
            lat,
            lng,
            isDefault: true,
            zoom: 15
          };

          setLocation(location);
        }
      );
  }, [placePredictions]);

  const setLocation = (location) => {
    const locationArray = location.address.split(', ');
    let url = '';

    localStorage.setItem('location', JSON.stringify(location));

    if (locationArray[1] === 'NY' && locationArray[0] !== 'New York') {
      url = `/${locationArray[1].toLowerCase()}c/${locationArray[0].toLowerCase().replace(' ', '-')}${locationFromURL.search}`;
    } else {
      url = `/${locationArray[0].toLowerCase().replace(' ', '-')}${locationFromURL.search}`;
    }

    window.location.href = url;
  };

  const handleLocation = (e, location) => {
    e.preventDefault();
    getPlacePredictions({ input: location });
  };

  return (
    <div className='map-list-container'>
      <h2 className='text-center'>Free or Cheap Parking Options for You</h2>
      <h2 className='text-center'>Book now/ Online discounts/ Best rates</h2>
      <br />
      <h2 className='text-center'>Cities</h2>
      <br />
      <ul>
        {LIST.map(({ name, type, location }) => type === 'link'
          ? <li key={name}><a onClick={(e) => handleLocation(e, location)}>{name}</a></li>
          : <li key={name}>{name}</li>
        )}
      </ul>
    </div>
  );
}

export default CitiesList;


// import { database } from '../utils/firebase';

// action types

export const SET_UID = 'SET_UID';
export const SET_MENU = 'SET_MENU';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PARKING_DATA = 'SET_PARKING_DATA';
export const SET_BANK_DATA = 'SET_BANK_DATA';
export const SET_REQUIREMENTS_DATA = 'SET_REQUIREMENTS_DATA';
export const SET_COMPANY_STATUS = 'SET_COMPANY_STATUS';
export const SET_NOTIFICATIONS_DATA = 'SET_NOTIFICATIONS_DATA';
export const SET_NOTIFICATIONS_COUNTER = 'SET_NOTIFICATIONS_COUNTER';
export const SET_NOTIFICATIONS_REQ = 'SET_NOTIFICATIONS_REQ';


// action creators

export const setUid = (uid) => {
    return {
        type: SET_UID,
        uid
    }
};

export const setMenu = (menu) => {
    return {
        type: SET_MENU,
        menu
    }
};

export const setUserData = (userData) => {
    return {
        type: SET_USER_DATA,
        userData
    }
};

export const setParkingData = (parkingData) => {
    return {
        type: SET_PARKING_DATA,
        parkingData
    }
};

export const setBankData = (bankData) => {
    return {
        type: SET_BANK_DATA,
        bankData
    }
};

export const setCompanyStatus = (status) => {
    return {
        type: SET_COMPANY_STATUS,
        status
    }
};

export const setRequirementsData = (requirementsData) => {
    return {
        type: SET_REQUIREMENTS_DATA,
        requirementsData
    }
};

export const setNotificationsCounter = (notificationsCounter) => {
    return {
        type: SET_NOTIFICATIONS_COUNTER,
        notificationsCounter
    }
};

export const setNotificationsData = (notificationsData) => {
    return {
        type: SET_NOTIFICATIONS_DATA,
        notificationsData
    }
};

export const setNotificationsReq = (notificationsReq) => {
    return {
        type: SET_NOTIFICATIONS_REQ,
        notificationsReq
    }
};